<template>
    <v-container class="grey lighten-5">
        <v-row class="mb-6" no-gutters>
            <v-col cols="1"></v-col>
            <v-col cols="12">
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="users"
                        :search="search"
                        sort-by="_created"
                        :sort-desc.sync="sortDesc"
                        class="elevation-1"
                        :custom-sort="customSort"
                    >
                        <template
                            v-slot:item._created="{ item }"
                        >
                            {{ new Date(item._created).toLocaleString('ru', {
    day:   '2-digit',
    month: '2-digit',
    year:  'numeric'
  }) }}
                        </template>
                  
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Users</v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="search"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialog" max-width="500px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="primary"
                                            class="mb-2"
                                            v-on="on"
                                            disabled
                                            >New User</v-btn
                                        >
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">
                                                {{ formTitle }}
                                            </span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-text-field
                                                        v-model="
                                                            editedItem.email
                                                        "
                                                        label="Email"
                                                        readonly
                                                    ></v-text-field>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="4"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                editedItem.first_name
                                                            "
                                                            label="First name"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="4"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                editedItem.last_name
                                                            "
                                                            label="Last name"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="4"
                                                    >
                                                        <v-checkbox
                                                            v-model="
                                                                editedItem.active
                                                            "
                                                            label="Active"
                                                        ></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="close"
                                                >Cancel</v-btn
                                            >
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="save"
                                                >Save</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog
                                    v-model="dialogUserCourses"
                                    max-width="500px"
                                >
                                    <UserCourseSelect
                                        @close_select="
                                            dialogUserCourses = false
                                        "
                                        :user="cur_user"
                                        :courses_for_list="user_courses"
                                        :key ="key_user_select"
                                        :courses="COURSES"
                                    />
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)"
                                >edit</v-icon
                            >
                            <v-icon
                                small
                                class="mr-2"
                                @click="editCourses(item)"
                                >mdi-format-list-bulleted-type</v-icon
                            >
                            <v-icon small disabled @click="deleteItem(item)"
                                >delete</v-icon
                            >
                        </template>
                        <template v-slot:no-data>
                            <v-btn color="primary" @click="initialize"
                                >Reset</v-btn
                            >
                        </template>
                        <template v-slot:item.active="{ item }">
                            <v-chip
                                :color="getColor(item.active)"
                                dark
                            ></v-chip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="3">
                <!-- <UserCard /> -->
            </v-col>

            <v-col cols="1">
                <!-- <NavigationCoursesButtons /> -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
// import NavigationCoursesButtons from "../components/navs/NavigationCoursesButtons";
import UserCourseSelect from "../components/course/UserCourseSelect";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Users",
    components: { UserCourseSelect },

    data: () => ({
        title: "Users",
        loading: null,
        src_state: null,
        cur_user: null,
        sortDesc: false,
        search: "",
        dialog: false,
        dialogUserCourses: false,
        key_user_select: 0,
        headers: [
            { text: "date", value: "_created" },
            { text: "email", value: "email" },
            { text: "Last name", value: "last_name" },
            { text: "First name", value: "first_name" },
            { text: "Phone", value: "cell_phone" },
            { text: "active", value: "active" },
            { text: "", value: "action", sortable: false }
        ],
        // users: [],

        editedIndex: -1,
        editedItem: {
            first_name: "",
            last_name: 0,
            email: 0,
            active: 0
        },
        defaultItem: {
            first_name: "",
            last_name: "",
            email: "",
            active: false
        }
    }),
    computed: {
        ...mapGetters({
            users: "USERS",
            COURSES: "COURSES"
        }),
        
        COMPUTED_PROPERTY: function() {
      return this.item.created1 + " Computed"
    },
        created2(item) {
            return item;
        },
        formTitle() {
            return this.editedIndex === -1 ? "New User" : "Edit User";
        },
        user_courses() {
            if (!this.cur_user) {
                return [];
            }

            let courses_for_list = [];
            this.COURSES.forEach(element => {
                // console.log(
                //     "components/course/UserCourseSelect.vue fill_course 1 element: %o",
                //     element
                // );
                let inCourse = this.cur_user.course_ids.indexOf(element._id);
                courses_for_list[element._id] = inCourse >= 0;
                // console.log(
                //     "components/course/UserCourseSelect.vue fill_course 2 inCourse: %o",
                //     inCourse
                // );
            });
            return courses_for_list;
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogUserCourses(val) {
            // console.log("views/Users.vue dialogUserCourses val: %o", val);
            if (val) {
                // console.log(
                //     "views/Users.vue dialogUserCourses this.editedItem: %o",
                //     this.editedItem
                // );
                this.cur_user = this.editedItem;
            }

            val || this.closeDialogUserCourses();
        }
    },
    created: async function() {
        // this.courses = await this.$store.dispatch("getUsers");
        this.loading = false;
        this.initialize();
    },

    methods: {
        ...mapActions({
            USERS: "USERS",
            UPDATE_USER_STATE: "UPDATE_USER_STATE",
            GET_COURSES: "COURSES"
        }),
customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='_created') {
            if (!isDesc[0]) {
                return new Date(a[index]) - new Date(b[index]);
            } else {
                return new Date(b[index]) - new Date(a[index]);
            }
          }
          else if (index[0]=='active'){
            if (!isDesc[0]) {
                return a[index] - b[index];
            } else {
                return b[index] - a[index];
            }
            // if (!isDesc[0]) {
            //     return new Date(a[index]) - new Date(b[index]);
            // } else {
            //     return new Date(b[index]) - new Date(a[index]);
            // }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },        
        initialize() {
            this.USERS();
            this.GET_COURSES();
        },

        editItem(item) {
            // console.log("views/Users.vue editItem item: %o", item);
            this.src_state = item.active;
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        editCourses(item) {
            // console.log("views/Users.vue editCourses item: %o", item);
            this.src_state = item.active;
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogUserCourses = true;
            this.key_user_select+=1;
        },

        deleteItem(item) {
            const index = this.users.indexOf(item);
            confirm("Are you sure you want to delete this user?") &&
                this.users.splice(index, 1);
        },
        closeDialogUserCourses() {
            this.dialogUserCourses = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        getColor(active) {
            if (active) return "green";
            else return "red";
        },

        save() {
            // console.log("views/Users.vue save");
            if (this.editedIndex > -1) {
                // console.log(
                //     "views/Users.vue save editedIndex=%o editedItem=%o user: %o this.src_state: %o",
                //     this.editedIndex,
                //     this.editedItem,
                //     this.users[this.editedIndex],
                //     this.src_state
                // );
                if (this.src_state != this.editedItem.active) {
                    Object.assign(
                        this.users[this.editedIndex],
                        this.editedItem
                    );
                    this.UPDATE_USER_STATE({
                        index: this.editedIndex,
                        item: this.editedItem
                    });
                }
            } else {
                // console.log(
                //     "views/Users.vue save this.editedItem=%o",
                //     this.editedItem
                // );
                this.users.push(this.editedItem);
            }
            this.close();
        }
    }
};
</script>

<style>
@import "../assets/css/app.css";
</style>
