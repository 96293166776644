export default {
    is_mobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    message_on(this_, resp) {
        if (resp.message !== null) {
            this_.show_message = true;
            this_.message = resp.message;
            return false;
        }
        return true;
    },
}
