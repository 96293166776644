<template>
  <v-container>

    <v-card
      class="custom-debug d-flex justify-center custom-title-outer custom-title-top"
      :elevation="debug_elevation"
    >
      <v-card
        class="custom-debug custom-title-inner"
        :elevation="elevation"
      >
        <v-card-title
            v-html="title"
            class="custom-debug d-flex justify-lg-center display-2"
        ></v-card-title>
      </v-card>
    </v-card>
    <v-card
      class="custom-debug d-flex justify-center custom-title-outer"
      :elevation="debug_elevation"
    >
      <v-card
        class="custom-debug custom-title-inner"
        :elevation="elevation"
      >
        <v-card-title
          class="custom-debug justify-center display-1 custom-debug"
        >
          {{class_status}}
        </v-card-title>
        <v-card-text
          class="custom-debug d-flex justify-center title custom-debug"
        >
          Лучше, чем у {{best_than}}% студентов
        </v-card-text>
        <v-card-text
          class="custom-debug d-flex justify-space-between custom-debug"
          style="padding: 0px"
        >
            <v-col
                class="custom-debug d-flex justify-end custom-debug text-center subtitle-1"
                style="display: block; flex-direction: column;"
                md="3"
            >
                <v-container class="custom-debug">Правильные ответы</v-container>
                <v-container class="custom-debug">
                    <v-btn class="ma-2" outlined fab color="green">
                        <b><v-content class="title" style="padding-right: 2px">{{true_answers}}</v-content></b>
                    </v-btn>
                </v-container>
            </v-col>
          <v-col
            class="custom-debug d-flex justify-start custom-debug text-center subtitle-1"
            style="display: block; flex-direction: column;"
            md="3"
          >
              <v-container class="custom-debug">Неверные ответы</v-container>
              <v-container class="custom-debug">
                  <v-btn class="ma-2" outlined fab color="red">
                      <b><v-content class="title" style="padding-right: 2px">{{false_answers}}</v-content></b>
                  </v-btn>
              </v-container>
          </v-col>
            <v-col
                class="custom-debug d-flex justify-center custom-debug text-center subtitle-1"
                style="display: block; flex-direction: column;"
                md="3"
            >
                <v-container class="custom-debug">Всего ответов</v-container>
                <v-container class="custom-debug">
                    <v-btn class="ma-2" outlined fab color="grey">
                        <b><v-content class="title" style="padding-right: 0px">{{true_answers+false_answers}}</v-content></b>
                    </v-btn>
                </v-container>
            </v-col>
        </v-card-text>
      </v-card>
    </v-card>

  </v-container>
</template>

<script>
  export default {
    name: "ClassTitle",
    props: ['elevation', 'debug_elevation'],
    computed: {
        class_status() {
            return `Глава завершена на ` + Math.round(this.percent_finished) + '%';
        },
        percent_finished() {
            return (100 * (this.false_answers + this.true_answers) / this.all_answers);
        }
    },
    data: () => ({
        title: `Глава 1. Привет! Как дела?`,

        true_answers: 250,
        false_answers: 15,
        all_answers: 300,

        best_than: 25,
    }),
  }
</script>

<style scoped>
    @import '../../assets/css/class_title.css';
</style>
