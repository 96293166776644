<template>
    <v-container>
        <v-app-bar app clipped-left color="#FFC107">
            <v-app-bar-nav-icon @click="goTohome()"></v-app-bar-nav-icon>
            <span class="title ml-3 mr-5"
                >Fless&nbsp; <span class="font-weight-light">Course</span></span
            >
        </v-app-bar>
        <v-row class="mb-6" no-gutters>
            <v-col cols="3" class="mx-0 px-0"
                >Course
                <hr />
                <v-expansion-panels focusable class="mx-2 px-2">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-chip class="ma-2" label v-if="course">
                                {{ course.title }}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ course }} 
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <CourseCard
            />
            </v-col>
            <v-col cols="4" class="mx-0 px-0">
                Classess
                <br />
                <hr />
                <v-expansion-panels focusable class="mx-2 px-2">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-chip class="ma-2" label v-if="SELECTED_CLASS">
                                {{ SELECTED_CLASS.title }}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ SELECTED_CLASS }} 
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-row class="mb-6 mx-0 px-0" no-gutters>
                    <v-col cols="1"></v-col>
                    <v-col cols="10">
                        <CourseClasses :current_course="course" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5">
                <v-row class="mb-6" no-gutters>
                    <v-col cols="11"
                        >Blocks
                        <hr />
                <v-expansion-panels focusable class="mx-2 px-2">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-chip class="ma-2" label v-if="SELECTED_BLOCK">
                                {{ SELECTED_BLOCK.title }}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ SELECTED_BLOCK }} 
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                        <ClassBlocks
                            v-bind:currentclass="this.getSelectedClass"
                            v-if="is_show_blocks(this.getSelectedClass)"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
            <NavigationCoursesButtons
            class="custom-app-containers"
            v-show="!loading"
        />
    </v-container>
</template>
<script>
import NavigationCoursesButtons from "../components/navs/NavigationCoursesButtons2";
// import UserCard from "../components/course/UserCard";
// import NavToolBar from "../components/navs/NavToolBar";
import CourseCard from "../components/course/CourseCardSelected";
import CourseClasses from "../components/class/Classes";
import ClassBlocks from "../components/class/Blocks";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
    name: "Classes",
    components: { CourseCard, CourseClasses, ClassBlocks, NavigationCoursesButtons },
    // components: { CourseCard, CourseClasses },

    data: () => ({
        title: "Course",
        loading: null,

        search: "",
        dialog: false,
        headers: [
            { text: "email", value: "email" },
            { text: "Last name", value: "last_name" },
            { text: "First name", value: "first_name" },
            { text: "active", value: "active" },
            { text: "", value: "action", sortable: false }
        ],
        // users: [],

        editedIndex: -1,
        editedItem: {
            first_name: "",
            last_name: 0,
            email: 0,
            active: 0
        },
        defaultItem: {
            first_name: "",
            last_name: "",
            email: "",
            active: false
        }
    }),
    computed: {
        ...mapGetters({
            course: "SELECTED_COURSE",
            COURSE_CLASSES: "COURSE_CLASSES",
            SELECTED_CLASS: "SELECTED_CLASS",
            getSelectedClass: "getSelectedClass",
            SELECTED_COURSE_ID: "SELECTED_COURSE_ID",
            SELECTED_BLOCK: "SELECTED_BLOCK"
        }),
        ...mapState(["SELECTED_COURSE"]),
        formTitle() {
            return this.editedIndex === -1 ? "New User" : "Edit User";
        },
        sel_cl() {
            // console.log("views/Classes.vue sel_cl 1");
            return this.getSelectedClass;
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function() {
        // this.courses = await this.$store.dispatch("getUsers");
        // console.log("views/Classes.vue created 1");
        this.loading = false;
        // console.log("views/Classes.vue created 2 route %o", this.$route);
        await this.initialize(this.$route.params.id);
        // console.log("views/Classes.vue created 3  course: %o", this.$course);
    },
    beforeCreate() {
        // console.log("views/Classes.vue beforeCreate 1");
    },
    beforeMount() {
        // console.log("views/Classes.vue beforeMount 1");
    },
    mounted() {
        // console.log("views/Classes.vue mounted 1");
    },
    beforeUpdate() {
        // console.log(
        //     "views/Classes.vue beforeUpdate 1  this.course %o  this.$course %o SELECTED_COURSE %o",
        //     this.course,
        //     this.$course,
        //     this.SELECTED_COURSE
        // );
    },
    updated() {
        // console.log(
        //     "views/Classes.vue updated 1  this.course %o  this.$course %o this.SELECTED_COURSE %o",
        //     this.course,
        //     this.$course,
        //     this.SELECTED_COURSE
        // );
    },
    methods: {
        ...mapActions(["COURSE", "CLASSES"]),
        ...mapMutations({
            set_selected_class: "SET_SELECTED_CLASS",
            SET_SELECTED_BLOCK: "SET_SELECTED_BLOCK",
            SET_SELECTED_CLASS: "SET_SELECTED_CLASS",
            SET_CLASSES: "SET_CLASSES",
            SET_SELECTED_COURSE_ID: "SET_SELECTED_COURSE_ID"
        }),
        async initialize(id) {
            // console.log("views/Classes.vue initialize 1 id: %o", id);
            this.SET_SELECTED_COURSE_ID(id);
            this.SET_SELECTED_CLASS(null);
            this.SET_CLASSES([]);
            await this.COURSE(id);
            // console.log("views/Classes.vue initialize 2 id: %o", id);
            // console.log(
            //     "views/Classes.vue initialize 3 this.course: %o",
            //     this.course
            // );
            this.set_selected_class(null);
            await this.CLASSES(id);
            // console.log("views/Classes.vue initialize 4 id: %o", id);
        },
        goTohome() {
            this.$router.push({ name: "home" });
        },
        editItem(item) {
            // console.log("views/Classes.vue editItem: %o", item);
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            const index = this.users.indexOf(item);
            confirm("Are you sure you want to delete this user?") &&
                this.users.splice(index, 1);
        },

        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        getColor(active) {
            if (active) return "green";
            else return "red";
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.users[this.editedIndex], this.editedItem);
            } else {
                this.users.push(this.editedItem);
            }
            this.close();
        },
        is_show_blocks(selected_class) {
            // console.log(
            //     "views/Classes.vue 1 is_show_blocks selected_class: %o",
            //     selected_class
            // );
            let is_show = selected_class && selected_class.block_ids.length > 0;
            // console.log(
            //     "views/Classes.vue 1 is_show_blocks is_show: %o ",
            //     is_show
            // );
            return is_show;
        }
    }
};
</script>

<style>
@import "../assets/css/app.css";
</style>
