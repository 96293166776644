<template>
    <v-list flat>
        <v-list-item v-for="(item, index) in this.courses" :key="item._id" 
         >
            <template v-slot:default>
                <v-list-item-action>
                    <v-row>
                        <v-col
                            cols="2"
                            class="text-center py-5 "
                            style="min-width: 70px"
                        >
                            <v-switch
                                v-model="user_on_course[index]"
                                color="success"
                                hide-details
                                v-on:change="changeItem(item, index)"
                                :disabled="is_course_active(item, index)"
                                height="15px"
                            ></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                dense
                                outlined
                                @click="openCourse(index, item)"
                                :disabled="
                                    !user_on_course[index] || !item.available
                                "
                            >
                                Complete
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="1"
                            md="1"
                            offset-md="1 "
                            class="text-center py-2 pl-1"
                        >
                            <v-progress-circular
                                :rotate="-90"
                                :size="50"
                                :width="10"
                                :value="get_progress(index)"
                                color="primary"
                                v-if="is_user_show_progress(item, index)"
                            >
                                {{ get_progress(index) }}
                            </v-progress-circular>
                            <v-spacer style="min-width: 50px"></v-spacer>
                        </v-col>
                    </v-row>
                </v-list-item-action>

                <v-list-item-content >
                    <v-list-item-title  :class="item.available ? 'active_course_title' : 'not_active_course_title' ">
                        {{ item.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle :class="item.available ? 'active_course_subtitle' : 'not_active_course_subtitle' ">{{
                        item.description
                    }}</v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-list-item>
    </v-list>
</template>
<script>
const file = "components/iter2/users/Card.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff0000";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";
// import Loading from "../Loading";
export default {
    name: "Card",
    // components: { Loading },
    props: ["user", "courses", "courses_for_list"],
    computed: {
        ...mapGetters({
            STATUSES: "STATUSES",
            COURSES_USER: "COURSES_USER"
        })
        // user_on_course(item){
        // console.log("components/class/UserCourseSelect.vue user_on_course 1  item: %o ", item);
        // return false
        // }
    },
    created: async function() {
        console.log(
            `%c${file}   created 1 this %o  this.user %o`,
            compColor,
            this,
            this.user
        );
        this.SET_COURSES_USER(this.courses);
        this.statuses = await this.USER_STATUSES(this.user._id);
        console.log(
            `%c${file}   created 1 statuses %o `,
            compColor,
            this.statuses
        );
        this.statuses = this.STATUSES;
        console.log(
            `%c${file}   created 2 statuses %o `,
            compColor,
            this.statuses
        );
        this.user_on_course.splice(0, this.user_on_course.length);
        this.user_on_course_info.splice(0, this.user_on_course_info.length);

        let i = 0;
        for (var key in this.courses) {
            let on_course = false;
            let status = this.statuses.find(
                el => el.course_id == this.courses[key]._id
            );
            let paid = false;
            let progress = 0;
            if (status != undefined && status.paid) {
                on_course = true;
                paid = true;
                // progress = 25;
            }
            this.user_on_course.push(on_course);
            let user_on_course_info = {
                index: i,
                key: key,
                paid: paid,
                progress: progress
            };
            if (on_course) {
                let meta = await this.COURSE_USER_META({
                    course_id: this.courses[key]._id,
                    user_id: this.user._id
                });
                user_on_course_info["progress"] = meta.progress;
            } else {
                this.SET_COURSE_USER_INFO({
                    course_id: this.courses[key]._id,
                    field: "meta",
                    meta: {  progress: 0, finished: false  }
                });
            }
            this.user_on_course_info.push(user_on_course_info);
            Vue.set(this.user_on_course, i, this.user_on_course[i]);

            i++;
        }

        this.user_on_course.splice(this.user_on_course.length);
        console.log(
            `%c${file}  created 3 user_on_course %o this.user_on_course_info %o  COURSES_USER %o`,
            compColor,
            this.user_on_course,
            this.user_on_course_info,
            this.COURSES_USER
        );
    },
    methods: {
        ...mapActions({
            UPDATE_USER_COURSES: "UPDATE_USER_COURSES",
            USER_STATUSES: "USER_STATUSES",
            ADD_USER_TO_COURSE: "ADD_USER_TO_COURSE",
            COURSE_USER_META: "COURSE_USER_META",
            COURSE_OPEN: "COURSE_OPEN"
        }),
        ...mapMutations({
            SET_COURSES_USER: "SET_COURSES_USER",
            SET_COURSE_USER_INFO: "SET_COURSE_USER_INFO"
        }),
        openCourse(index, item) {
            console.log(
                `%c${file} openCourse  index %o  item %o user %o`,
                compColorI,
                index,
                item, this.user
            );
            this.COURSE_OPEN({
                course_id: item._id,
                user_email: this.user.email})
        },
        is_user_on_course(item, index) {
            console.log(
                `%c${file} is_user_on_course  index %o   %o  item.available item %o  this.user %o`,
                compColorI,
                index,
                this.user.course_ids.includes(item._id),
                item.available,
                item,
                this.user
            );

            return !(this.user.course_ids.includes(item._id) && item.available);
        },

        get_progress(index) {
            if (this.COURSES_USER[index].meta == undefined) {
                return 0;
            } else {
                return (this.COURSES_USER[index].meta.progress * 100).toFixed(
                    0
                );
            }
        },

        is_user_show_progress(item) {
            // console.log(
            //     `%c${file} is_user_show_progress  index %o   %o  item.available %o item %o  this.user %o`,
            //     compColorI,
            //     index,
            //     this.user.course_ids.includes(item._id),
            //     item.available,
            //     item,
            //     this.user
            // );

            return this.user.course_ids.includes(item._id) && item.available;
        },

        is_course_active(course) {
            // console.log(
            //     `%c${file} is_course_active  index %o   %o  course.available %o course %o  this.user %o`,
            //     compColorI,
            //     index,
            //     this.user.course_ids.includes(course._id),
            //     course.available,
            //     course,
            //     this.user
            // );

            // return !this.courses[index].available;
            return !course.available;
        },

        is_user_on_not_active_course(item, index) {
            return !this.courses[index].available;
        },

        is_course_paid(index) {
            return this.user_on_course_info[index] !== undefined
                ? this.user_on_course_info[index].paid
                : false;
        },

        close() {
            this.$emit("close_select");
        },
        async changeItem(item, index) {
            console.log(
                `%c${file} changeItem  1 index %o   %o  item.available item %o  this.user %o `,
                compColorI,
                index,
                this.user.course_ids.includes(item._id),
                item.available,
                item,
                this.user
            );
            console.log(
                `%c${file} changeItem  2 user_on_course.paid %o user_on_course_info %o  this.statuses %o`,
                compColorI,
                this.user_on_course[index],
                this.user_on_course_info,
                this.statuses
            );
            let data = {
                course_id: item._id,
                user_id: this.user._id,
                paid: this.user_on_course[index]
            };
            await this.ADD_USER_TO_COURSE(data);
            this.user_on_course_info[index].paid = this.user_on_course[index];
            console.log(
                `%c${file} changeItem  3 this.COURSES_USER[index] %o `,
                compColorI,
                this.COURSES_USER[index]
            );
            let meta = this.COURSES_USER[index].meta
            meta.paid=this.user_on_course[index]
            this.SET_COURSE_USER_INFO({
                    course_id:  item._id,
                    field: "meta",
                    meta: meta
                });
            console.log(
                `%c${file} changeItem  4 this.COURSES_USER[index] %o `,
                compColorI,
                this.COURSES_USER[index]
            );

        }
    },
    data: () => ({
        loading: false,
        statuses: [],
        user_on_course: [],
        user_on_course_info: [],
        value: 25
    })
};
</script>

<style scoped>
@import "../../../assets/iter2/css/navigation_buttons.css";
/* .v-list-item__title__ {
  color: green  !important;  
}

.active_course {
  color: gray  !important;  
} */

.active_course_title__ {
  color: rgb(37, 37, 37) !important;  
}

.active_course_title {
  color: rgb(0, 0, 0) !important;  
}
.active_course_subtitle {
  color: rgb(61, 61, 61) !important;  
}

.not_active_course_title {
  color: rgb(119, 117, 117) !important;  
}
.not_active_course_subtitle {
  color: rgb(163, 160, 160) !important;  
}

</style>
