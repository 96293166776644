<template>
    <v-card max-width="475" class="mx-auto">
        <v-toolbar color="teal" dark>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title
                >Select {{ this.user.last_name }}
                {{ this.user.first_name }} courses
            </v-toolbar-title>
        </v-toolbar>

        <v-list subheader two-line flat>
            <v-subheader>Select User Courses </v-subheader>

            <v-list-item v-for="(item, index) in this.courses" :key="item._id">
                <template v-slot:default>
                    <v-list-item-action>
                        <v-switch
                            v-model="user_on_course[index]"
                            color="success"
                            hide-details
                            v-on:change="changeItem(item, index)"
                            :disabled="is_course_active(item, index)"
                        ></v-switch>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.description
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text
                            v-text="item.action"
                        ></v-list-item-action-text>
                        <v-icon v-if="!item.available" color="grey lighten-1">
                            star_border
                        </v-icon>

                        <v-icon v-else color="amber">
                            star
                        </v-icon>
                    </v-list-item-action>
                </template>
            </v-list-item>
        </v-list>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
// import Loading from "../Loading";
export default {
    name: "UserCourseSelect",
    // components: { Loading },
    props: ["user", "courses", "courses_for_list"],
    computed: {
        ...mapGetters({
            STATUSES: "STATUSES"
        })
        // user_on_course(item){
        // console.log("components/class/UserCourseSelect.vue user_on_course 1  item: %o ", item);
        // return false
        // }
    },
    created: async function() {
        this.statuses = await this.USER_STATUSES(this.user._id);
        this.statuses = this.STATUSES;
        this.user_on_course.splice(0, this.user_on_course.length);
        this.user_on_course_info.splice(0, this.user_on_course_info.length);

        let i = 0;
        for (var key in this.courses) {
            let on_course = false;
            let status = this.statuses.find(el => el.course_id == this.courses[key]._id)
            if (status!= undefined && status.paid){
                on_course=true
            }
            this.user_on_course.push(on_course);
            this.user_on_course_info.push({ index: i, key: key });
            Vue.set(this.user_on_course, i, this.user_on_course[i]);

            i++;
        }

        this.user_on_course.splice(this.user_on_course.length);
    },
    methods: {
        ...mapActions({
            UPDATE_USER_COURSES: "UPDATE_USER_COURSES",
            USER_STATUSES: "USER_STATUSES",
            ADD_USER_TO_COURSE: "ADD_USER_TO_COURSE"
        }),
        is_user_on_course(item) {
            return this.user.course_ids.includes(item._id);
        },

        is_course_active(item, index) {
            return !this.courses[index].available;
        },

        close() {
            this.$emit("close_select");
        },
        async changeItem(item, index) {
            let data={course_id: item._id, user_id: this.user._id, paid: this.user_on_course[index]}
            await this.ADD_USER_TO_COURSE(data)
        },

    },
    data: () => ({
        loading: false,
        statuses: [],
        user_on_course: [],
        user_on_course_info: []
    })
};
</script>

<style scoped>
@import "../../assets/css/navigation_buttons.css";
</style>
