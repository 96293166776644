<template>
    <v-app class="background-color" style="padding: 15px 80px 0 80px">
        <v-snackbar v-model="show_message">
            {{ message }}
        </v-snackbar>
        <Loading v-if="loading" class="text-center" :title="title" />
        <Navigations v-if="!loading" />
        <v-card
            v-if="!loading"
            :elevation="settings.elevation"
            style="min-height: 550px; overflow: hidden"
        >
            <div style="padding: 40px">
                <div
                    style="display: flex; height: 360px; padding-left: 10px; margin-bottom: 25px; overflow: hidden"
                >
                    <div style="width: 60%; padding: 0 25px 0 0">
                        <div>
                            <router-link
                                :style="{ color: settings.general_color }"
                                style="text-decoration: none"
                                :to="{ path: '/v2/classes/' + course._id }"
                            >
                                <b>&lt; Back to classes </b>
                            </router-link>
                        </div>
                        <v-row>
                            <v-col cols="1">
                                <v-btn
                                    icon
                                    color="primary"
                                    dark
                                    large
                                    style="margin: 20px 0 20px 0"
                                    @click.stop="openEditTitleDialog"
                                >
                                    <v-icon>mdi-pencil-box-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="11">
                                <div
                                    class="display-1"
                                    style="margin: 20px 0 20px 0"
                                >
                                    <b>{{ class_title }}</b>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1">
                                <v-btn
                                    icon
                                    color="primary"
                                    @click.stop="openEditDescriptionDialog"
                                    dark
                                    large
                                >
                                    <v-icon>mdi-pencil-box-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="11">
                                <v-textarea
                                    no-resize
                                    dense
                                    readonly
                                    outlined
                                    auto-grow
                                    rows="1"
                                    v-model="class_description"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </v-card>
        <h1 v-if="!loading" style="margin-top: 35px; font-size: 32px">
            <b>Blocks</b>
        </h1>
        <div style="margin-bottom: 70px" v-if="!loading">
            <Card
                v-for="(cl, i) in BLOCKS"
                :key="i"
                :cl="cl"
                style="width: 100%; margin-top: 45px; border-radius: 8px;"
            />
        </div>
        <EditLineDialog ref="editTitle" />
        <EditTextAreaDialog ref="editDescription" />
    </v-app>
</template>

<script>
const file = "views/iter2/Class_.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff00B0";
import Navigations from "../../components/iter2/Navigations";
import Loading from "../../components/iter2/Loading";
import Card from "../../components/iter2/blocks/Card";
import EditLineDialog from "../../components/iter2/EditLineDialog";
import EditTextAreaDialog from "../../components/iter2/EditTextAreaDialog";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
export default {
    name: "Class_",
    components: {
        Card,
        Navigations,
        Loading,
        EditLineDialog,
        EditTextAreaDialog
    },
    data: () => ({
        classes: [],
        loading: true,

        settings: null,
        show_message: false,
        message: "",
        title: "Your classes",

        class_title: "",
        class_description: ""
    }),
    created: async function() {
        console.log(
            `%c${file} created 1 this.settings %o`,
            compColor,
            this.settings
        );
        this.settings = this.get_settings;
        this.loading = true;
        this.settings = this.$store.getters.settings;
        await this.initialize(this.$route.params.id);

        this.loading = false;
    },
    computed: {
        ...mapGetters({
            get_settings: "settings",
            course: "SELECTED_COURSE",
            COURSE_CLASSES: "COURSE_CLASSES",
            SELECTED_CLASS: "SELECTED_CLASS",
            getSelectedClass: "getSelectedClass",
            BLOCKS: "BLOCKS",
            SELECTED_BLOCK: "SELECTED_BLOCK"
        }),
        ...mapState(["SELECTED_COURSE"])
    },
    methods: {
        ...mapActions([
            "COURSES",
            "COURSE",
            "CLASSES",
            "GET_BLOCKS_FOR_CLASS",
            "UPDATE_CLASS_ATTRIBUTES",
            "GET_CLASS"
        ]),
        ...mapMutations({
            set_selected_class: "SET_SELECTED_CLASS",
            SET_SELECTED_BLOCK: "SET_SELECTED_BLOCK",
            SET_SELECTED_CLASS: "SET_SELECTED_CLASS",
            SET_CLASSES: "SET_CLASSES",
            SET_SELECTED_COURSE_ID: "SET_SELECTED_COURSE_ID"
        }),
        async openEditTitleDialog() {
            if (
                await this.$refs.editTitle.open(
                    "Edit class title.",
                    "",
                    this.class_title
                )
            ) {
                console.log(
                    `%c${file} editTitle 1 editTitle %o`,
                    compColor,
                    this.$refs.editTitle
                );
                this.editTitle(this.$refs.editTitle.line);
            }
        },
        async editTitle(line) {
            console.log(`%c${file} editTitle 1 editTitle %o`, compColor, line);
            let class_id = this.SELECTED_CLASS._id;
            await this.UPDATE_CLASS_ATTRIBUTES({
                id: this.SELECTED_CLASS._id,
                etag: this.SELECTED_CLASS._etag,
                title: line,
                description: this.SELECTED_CLASS.description
            });
            console.log(
                `%c${file} editTitle 1 this.SELECTED_CLASS._id %o`,
                compColor,
                class_id
            );
            await this.GET_CLASS(class_id);
            await this.initialize(class_id);
        },
        async openEditDescriptionDialog() {
            if (
                await this.$refs.editDescription.open(
                    "Edit class description.",
                    "",
                    this.class_description
                )
            ) {
                console.log(
                    `%c${file} editDescription 1 editDescription %o`,
                    compColor,
                    this.$refs.editDescription
                );
                this.editDescription(this.$refs.editDescription.text_area);
            }
        },
        async editDescription(description) {
            console.log(
                `%c${file} editDescription 1  description %o`,
                compColor,
                description
            );
            let class_id = this.SELECTED_CLASS._id;
            await this.UPDATE_CLASS_ATTRIBUTES({
                id: this.SELECTED_CLASS._id,
                etag: this.SELECTED_CLASS._etag,
                title: this.SELECTED_CLASS.title,
                description: description
            });
            await this.GET_CLASS(class_id);
            await this.initialize(class_id);
        },
        async initialize(id) {
            console.log(
                `%c${file} initialize 1 id %o   this.course %o`,
                compColorI,
                id,
                this.SELECTED_CLASS
            );
            await this.GET_BLOCKS_FOR_CLASS(id);
            console.log(
                `%c${file} initialize 2 id %o this.SELECTED_CLASS %o, this.BLOCKS %o`,
                compColorI,
                id,
                this.SELECTED_CLASS,
                this.BLOCKS
            );
            this.class_title = this.SELECTED_CLASS.title;
            this.class_description = this.SELECTED_CLASS.description;
        },
        appendIconCallback() {
            alert("click:append");
        },
        prependIconCallback() {
            alert("click:prepend");
        }
    }
};
</script>

<style>
@import "../../assets/iter2/css/course_progress.css";
@import "../../assets/iter2/css/navigation_buttons.css";
@import "../../assets/iter2/css/app.css";
@import "../../assets/iter2/css/new/colors.css";
</style>
