<template>
    <v-app style="background-color: #ffffff;">
        <v-layout
            row
            style="margin-top: 1%; margin-left: 8%; margin-right: 8%"
            v-show="!loading"
        >
            <v-container
                class="custom-app-containers justify-center text-center display-1 my-0"
                
            >
                {{ title }}
            </v-container>
            <v-flex md4 v-for="course in courses" :key="course.id">
                <CourseCards :course="course" />
            </v-flex>
        </v-layout>
        <NavigationCoursesButtons
            class="custom-app-containers"
            v-show="!loading"
        />
        <Loading class="text-center" v-show="loading" />
    </v-app>
</template>

<script>
const file = "views/COurses/CoursesView.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff00B0";
import NavigationCoursesButtons from "../components/navs/NavigationCoursesButtons2";
import CourseCards from "../components/course/CourseCard";
import Loading from "../components/Loading";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "CoursesView",
    components: { Loading, CourseCards, NavigationCoursesButtons },
    created: async function() {
        this.loading = true;
        // this.courses = await this.$store.dispatch("getCourses");
        console.log(
            `%c${file} created this.courses %o`,
            compColor,
            this.courses
        );
        await this.COURSES();
        console.log(
            `%c${file} created this.courses %o`,
            compColorI,
            this.courses
        );
        this.loading = false;
    },
    data: () => ({
        title: "All courses",
        loading: null
        // courses: []
    }),
    methods: {
        ...mapActions([
            "COURSES"])
        },
    computed: {
        ...mapGetters({
            courses: "COURSES"
        })
    }


};
</script>

<style>
@import "../assets/css/app.css";
</style>
