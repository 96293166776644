import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import Execution from "./views/Execution";
// import Courses from "./views/Courses";
import Classes from "./views/Classes";
import Account from "./views/Account";
import Error from "./views/Error";
import Login from "./views/Login";
import Secure from "./components/Secure";
import Admin from "./views/Admin";
import Accounts from "./views/Accounts";
import Courses from "./views/Courses";
import AddCourse from "./views/AddCourse";
import Courses_i2 from "./views/iter2/Courses.vue";
import Classes_i2 from "./views/iter2/Classes.vue";
import Class__i2 from "./views/iter2/Class_.vue";
import Users_i2 from "./views/iter2/Users.vue";
import UserCourses_i2 from "./views/iter2/UserCourses.vue";
import AddCourse_i2 from "./views/iter2/AddCourse.vue";

Vue.use(Router);

let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/execution",
            name: "execution",
            component: Execution
        },
        {
            path: "/",
            name: "home",
            component: Courses,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/admin",
            name: "admin",
            component: Admin,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/course",
            name: "course",
            component: Courses,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/addcourse",
            name: "addcourse",
            component: AddCourse,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/course/:id",
            name: "course",
            component: Classes,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/classes/:id",
            name: "classes",
            component: Classes,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/login",
            name: "login",
            component: Login
        },
        {
            path: "/users",
            name: "users",
            component: Accounts,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/secure",
            name: "secure",
            component: Secure,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/v2/courses/",
            name: "v2_courses",
            component: Courses_i2
        },
        {
            path: "/v2/addcourse/",
            name: "v2_addcourse",
            component: AddCourse_i2
        },
        {
            path: "/v2/classes/:id",
            name: "v2_classes",
            component: Classes_i2
        },
        {
            path: "/v2/class/:id",
            name: "v2_class",
            component: Class__i2
        },
        {
            path: "/v2/users",
            name: "v2_users",
            component: Users_i2
        },
        {
            path: "/v2/user/:id/courses",
            name: "v2_user_courses",
            component: UserCourses_i2
        },
        {
            path: "/account",
            name: "account",
            component: Account
        },
        {
            // Эта страница с ошибкой. checking назвывается, чтобы не вызывать у пользователей раздражения от того, что
            // что-то упало
            path: "/checking",
            name: "error",
            component: Error
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next({ path: "/login", params: { nextUrl: to.fullPath } });
    } else {
        next();
    }
});
export default router;
