<template>
    <v-app class="background-color" style="padding: 15px 80px 0 80px">
        <v-snackbar v-model="show_message">
            {{ message }}
        </v-snackbar>
        <Loading v-if="loading" class="text-center" :title="title" />
        <Navigations v-if="!loading" />
        <v-container>
            <v-card
                v-if="!loading"
                :elevation="settings.elevation"
                style="min-height: 550px; overflow: hidden"
            >
                <div style="padding: 40px">
                    <v-row>
                        <v-col cols="9"> </v-col>
                        <v-col cols="2">
                            <router-link
                                :style="{
                                    color: settings.general_color
                                }"
                                style="text-decoration: none"
                                to="/v2/courses"
                            >
                                <b>&lt; Back to courses </b>
                            </router-link>
                        </v-col>
                        <v-col cols="1">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        color="primary"
                                        dark
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.stop="buttonActivateCourse()"
                                    >
                                        <v-icon v-if="available"
                                            >mdi-eye-off</v-icon
                                        >
                                        <v-icon v-else>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{
                                    available
                                        ? "Deactivate course"
                                        : "Activate course"
                                }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        color="primary"
                                        :dark="!available"
                                        small
                                        :disabled="available"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.stop="buttonDeleteCourse()"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete course</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <div
                        style="display: flex; height: 370px; padding-left: 10px; margin-bottom: 25px; overflow: hidden"
                    >
                        <div style="width: 60%; padding: 0 25px 0 0">
                            <v-row>
                                <v-col cols="1">
                                    <div
                                        class="display-1"
                                        style="margin: 20px 0 20px 0"
                                    >
                                        <v-btn
                                            icon
                                            color="primary"
                                            dark
                                            large
                                            @click.stop="openEditTitleDialog"
                                        >
                                            <v-icon
                                                >mdi-pencil-box-outline</v-icon
                                            >
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="11">
                                    <div
                                        class="display-1"
                                        style="margin: 20px 0 20px 0"
                                    >
                                        <b>{{ title }}</b>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1">
                                    <v-btn
                                        icon
                                        color="primary"
                                        dark
                                        large
                                        @click.stop="openEditDescriptionDialog"
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="11">
                                    <v-textarea
                                        no-resize
                                        dense
                                        readonly
                                        outlined
                                        auto-grow
                                        rows="1"
                                        v-model="description"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </div>
                        <div style="width: 40%; padding: 0 0 0 25px; ">
                            <v-row>
                                <v-col cols="1">
                                    <v-btn
                                        icon
                                        color="primary"
                                        dark
                                        large
                                        @click.stop="openEditImageUrlDialog"
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="11">
                                    <v-img
                                        :style="gray_scale(course.available)"
                                        :src="image_url"
                                    />
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="trial_time"
                                outlined
                                clearable
                                type="number"
                                dense
                                style="padding: 0 0 0 0; "
                                :rules="[test_trial_time]"
                                min="1"
                                step="1"
                            >
                                <template v-slot:prepend>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                icon
                                                color="primary"
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled="
                                                    trial_time_save_disabled
                                                "
                                                @click.stop="save_trial_time"
                                            >
                                                <v-icon
                                                    >mdi-content-save</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Save trial time</span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                            <v-text-field
                                v-model="payment_url"
                                outlined
                                clearable
                                type="text"
                                dense
                                style="padding: 0 0 0 0; margin: -20px 0 0 0;"
                                :rules="[test_payment_url]"
                            >
                                <template v-slot:prepend>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                icon
                                                color="primary"
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled="
                                                    payment_url_save_disabled
                                                "
                                                @click.stop="save_payment_url"
                                            >
                                                <v-icon
                                                    >mdi-content-save</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Save payment url</span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </div>
            </v-card>
            <CourseDelete
                :course="course"
                :openDialog="course_delete_dialog"
                :key="key_delete_dialog"
            />
        </v-container>

        <h1 v-if="!loading" style="margin-top: 35px; font-size: 32px">
            <b>Classes</b>
        </h1>
        <div style="margin-bottom: 70px" v-if="!loading">
            <Card
                v-for="(cl, i) in COURSE_CLASSES"
                :key="i"
                :cl="cl"
                style="width: 100%; margin-top: 45px"
            />
        </div>
        <EditLineDialog ref="editTitle" />
        <EditTextAreaDialog ref="editDescription" />
        <EditLineDialog ref="editImageUrl" />
    </v-app>
</template>

<script>
const file = "views/iter2/Classes.vue";
const compColor = "color: #9C27B0";
import Navigations from "../../components/iter2/Navigations";
import Loading from "../../components/iter2/Loading";
import Card from "../../components/iter2/classes/Card";
import CourseDelete from "../../components/course/CourseDelete";
import EditLineDialog from "../../components/iter2/EditLineDialog";
import EditTextAreaDialog from "../../components/iter2/EditTextAreaDialog";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
export default {
    name: "Classes",
    components: {
        Card,
        Navigations,
        Loading,
        CourseDelete,
        EditLineDialog,
        EditTextAreaDialog
    },
    data: () => ({
        loading: true,
        course_delete_dialog: false,
        key_delete_dialog: 1,

        settings: null,
        show_message: false,
        message: "",
        title: "Your classes",

        image_url: "",
        trial_time: 0,
        payment_url: "",
        available: true,
        description: "",
        trial_time_save_disabled: true,
        payment_url_save_disabled: true,
        showEditTitleDialog: false
        // rules_trial_time: {
        //     required:this.test_trial_time(value),
        //     counter: value => value.length <= 20 || "Max 20 characters"
        // }
    }),
    created: async function() {
        console.log(
            `%c${file} created this.settings %o`,
            compColor,
            this.settings
        );
        console.log(`%c${file} created this.course %o`, compColor, this.course);
        this.settings = this.get_settings;
        this.loading = true;
        this.settings = this.$store.getters.settings;
        await this.initialize(this.$route.params.id);

        this.loading = false;
        console.log(`%c${file} created this.course %o`, compColor, this.course);
    },
    computed: {
        ...mapGetters({
            get_settings: "settings",
            courses: "COURSES",
            course: "SELECTED_COURSE",
            COURSE_CLASSES: "COURSE_CLASSES",
            SELECTED_CLASS: "SELECTED_CLASS",
            getSelectedClass: "getSelectedClass",
            SELECTED_COURSE_ID: "SELECTED_COURSE_ID",
            SELECTED_BLOCK: "SELECTED_BLOCK",
            SELECTED_COURSE: "SELECTED_COURSE"
        }),
        ...mapState(["SELECTED_COURSE"])
    },
    methods: {
        async openEditTitleDialog() {
            if (
                await this.$refs.editTitle.open(
                    "Edit course title.",
                    "",
                    this.title
                )
            ) {
                console.log(
                    `%c${file} editTitle 1 editTitle %o`,
                    compColor,
                    this.$refs.editTitle
                );
                this.editTitle(this.$refs.editTitle.line);
            }
        },
        async editTitle(line) {
            await this.UPDATE_COURSE_ATTRIBUTES({
                id: this.course._id,
                etag: this.course._etag,
                title: line,
                description: this.course.description,
                image_url: this.course.image_url,
                payment_url: this.course.payment_url
            });
            await this.initialize(this.course._id);
        },
        async openEditDescriptionDialog() {
            if (
                await this.$refs.editDescription.open(
                    "Edit course description.",
                    "",
                    this.description
                )
            ) {
                console.log(
                    `%c${file} editDescription 1 editDescription %o`,
                    compColor,
                    this.$refs.editDescription
                );
                this.editDescription(this.$refs.editDescription.text_area);
            }
        },
        async editDescription(description) {
            console.log(
                `%c${file} editDescription 1  description %o`,
                compColor,
                description
            );
            await this.UPDATE_COURSE_ATTRIBUTES({
                id: this.course._id,
                etag: this.course._etag,
                title: this.course.title,
                description: description,
                image_url: this.course.image_url,
                payment_url: this.course.payment_url
            });
            await this.initialize(this.course._id);
            // this.description = this.course.description;
        },

        async openEditImageUrlDialog() {
            if (
                await this.$refs.editImageUrl.open(
                    "Edit image url.",
                    "",
                    this.image_url
                )
            ) {
                console.log(
                    `%c${file} EditImageUrl 1 EditImageUrl %o`,
                    compColor,
                    this.$refs.editImageUrl
                );
                this.editImageUrl(this.$refs.editImageUrl.line);
            }
        },
        async editImageUrl(line) {
            await this.UPDATE_COURSE_ATTRIBUTES({
                id: this.course._id,
                etag: this.course._etag,
                title:  this.course.title,
                description: this.course.description,
                image_url: line,
                payment_url: this.course.payment_url
            });
            await this.initialize(this.course._id);
        },

        
        async save_trial_time() {
            console.log(
                `%c${file} save_trial_time 1  trial_time %o`,
                compColor,
                this.trial_time
            );
            let data = {
                trial_time: this.trial_time,
                _etag: this.course._etag,
                _id: this.course._id
            };
            await this.UPDATE_COURSE_TRIAL_TIME(data);

            await this.initialize(this.course._id);
            // this.description = this.course.description;
        },
        async save_payment_url() {
            console.log(
                `%c${file} save_payment_url 1  payment_url %o`,
                compColor,
                this.payment_url
            );
            await this.UPDATE_COURSE_ATTRIBUTES({
                id: this.course._id,
                etag: this.course._etag,
                title: this.course.title,
                description: this.course.description,
                image_url: this.course.image_url,
                payment_url: this.payment_url
            });
            await this.initialize(this.course._id);
        },
        test_trial_time(value) {
            if (this.course.trial_time == value) {
                this.trial_time_save_disabled = true;
            } else {
                this.trial_time_save_disabled = false;
            }

            // return !!value || "Required.";
            return true;
        },
        test_payment_url(value) {
            if (this.course.payment_url == value) {
                this.payment_url_save_disabled = true;
            } else {
                this.payment_url_save_disabled = false;
            }

            // return !!value || "Required.";
            return true;
        },
        ...mapActions([
            "COURSES",
            "COURSE",
            "CLASSES",
            "UPDATE_COURSE_STATE",
            "UPDATE_COURSE_ATTRIBUTES",
            "UPDATE_COURSE_TRIAL_TIME"
        ]),
        ...mapMutations({
            set_selected_class: "SET_SELECTED_CLASS",
            SET_SELECTED_BLOCK: "SET_SELECTED_BLOCK",
            SET_SELECTED_CLASS: "SET_SELECTED_CLASS",
            SET_CLASSES: "SET_CLASSES",
            SET_SELECTED_COURSE_ID: "SET_SELECTED_COURSE_ID"
        }),
        gray_scale(available) {
            let style1 = !available ? `filter: grayscale(100%)` : ``;
            return ` border-radius: 5px; ${style1}`;
        },
        async initialize(id) {
            console.log(
                `%c${file} initialize 1 this.courses %o`,
                compColor,
                this.courses
            );
            if (this.courses.length == 0) {
                await this.COURSES();
            }
            console.log(
                `%c${file} initialize 2 this.courses %o`,
                compColor,
                this.courses
            );
            this.SET_SELECTED_COURSE_ID(id);
            this.SET_SELECTED_CLASS(null);
            this.SET_CLASSES([]);
            await this.COURSE(id);
            this.set_selected_class(null);
            await this.CLASSES(id);
            this.payment_url = this.course.payment_url;
            this.trial_time = this.course.trial_time;
            this.available = this.course.available;
            this.title = this.course.title;
            this.description = this.course.description;
            this.image_url = this.course.image_url;
        },
        appendIconCallback() {
            alert("click:append");
        },
        prependIconCallback() {
            alert("click:prepend");
        },
        buttonDeleteCourse() {
            console.log(
                `%c${file} Button Clicked event course_delete_dialog %o   key_delete_dialog %o`,
                compColor,
                this.course_delete_dialog,
                this.key_delete_dialog
            );

            this.course_delete_dialog = true;
            this.key_delete_dialog++;
        },
        async buttonActivateCourse() {
            console.log(
                `%c${file} "Button  buttonActivateCourse 1 available %o  this.course %o`,
                compColor,
                this.available,
                this.course
            );

            console.log(
                `%c${file} "Button buttonActivateCourse 2 SELECTED_COURSE %o `,
                compColor,
                this.course
            );
            // this.available = !this.available;
            await this.UPDATE_COURSE_STATE({
                course_id: this.course._id,
                active: !this.available
            });

            console.log(
                `%c${file} "Button buttonActivateCourse 3 SELECTED_COURSE %o `,
                compColor,
                this.course
            );
            // this.available = this.course.available;
            await this.initialize(this.course._id);
            console.log(
                `%c${file} "Button  buttonActivateCourse 4 available %o  this.course %o`,
                compColor,
                this.available,
                this.course
            );
        }
    }
};
</script>

<style>
@import "../../assets/iter2/css/course_progress.css";
@import "../../assets/iter2/css/navigation_buttons.css";
@import "../../assets/iter2/css/app.css";
@import "../../assets/iter2/css/new/colors.css";
</style>
