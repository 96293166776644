<template>
    <v-app
        class="custom-app custom-debug"
        style="background-color: #ffffff;"
    >
        <ClassTitle
            class="custom-debug custom-app-containers"
            :elevation="elevation"
            :debug_elevation="debug_elevation"
        />
        <v-container
            class="custom-debug custom-app-containers"
            v-for="(block, i) in blocks"
            :key="i"
        >
            <ClassBlockText
                class="custom-debug custom-app-containers"
                :title="block.title"
                :text="block.text"
                :elevation="elevation"
                :debug_elevation="debug_elevation"
                v-if="block.type === 'text'"
            />
            <ClassBlockTextQuestion
                class="custom-debug custom-app-containers"
                :title="block.title"
                :question="block.question"
                :checked="block.checked"
                :answer="block.answer"
                :accepted="block.accepted"
                :elevation="elevation"
                :debug_elevation="debug_elevation"
                v-else-if="block.type === 'text_question'"
            />
            <ClassBlockCheckboxQuestion
                class="custom-debug custom-app-containers"
                :title="block.title"
                :question="block.question"
                :checked="block.checked"
                :answer="block.answer"
                :elevation="elevation"
                :debug_elevation="debug_elevation"
                v-else-if="block.type === 'checkbox_question'"
            />
        </v-container>
        <v-card style="margin-bottom: 45px"></v-card>
        <ProgressBarBottom
            :all_answers="all_answers"
            :answered="answered"
        />
        <NavigationExecutionButtons
            class="custom-debug custom-app-containers"
        />
    </v-app>
</template>

<script>
    import NavigationExecutionButtons from "../components/navs/NavigationExecutionButtons";
    import ClassBlockText from "../components/class/block/Text";
    import ClassBlockTextQuestion from "../components/class/block/TextQuestion";
    import ProgressBarBottom from "../components/class/ProgressBarBottom";
    import ClassBlockCheckboxQuestion from "../components/class/block/CheckboxQuestion";
    import ClassTitle from "../components/class/Title";
    export default {
        name: 'Class',
        components: {
            ClassTitle,
            ClassBlockCheckboxQuestion,
            ProgressBarBottom, ClassBlockTextQuestion, ClassBlockText, NavigationExecutionButtons },
        computed: {
            answered() {
                let count = 0;
                for (let i = 0; i < this.blocks.length; i++) {
                    let a = 'checked' in this.blocks[i];
                    let b = this.blocks[i].type !== 'text';
                    if (a && b) {
                        count++;
                    }
                }
                return count;
            },
        },
        data: () => ( {
            title: 'Глава 1. Привет! Как дела?',
            all_answers: 32,
            blocks: [
                {
                    type: 'text',
                    title: 'Мэрилендская кампания',
                    text: '<p align="justify"><b>Мэрилендская кампания</b> (<i>англ. Maryland campaign</i>), или <b>Энтитемская кампания</b> (<i>Antietam campaign</i>), — одна из кампаний американской гражданской войны. Генерал Конфедерации Роберт Ли, разбив противника в Северной Вирджинии, смог осуществить первое вторжение на Север. Потомакская армия генерала Джорджа Макклеллана заставила генерала Ли отступить, что привело к сражению при Энтитеме, которое вошло в историю как самое кровопролитное однодневное сражение в американской истории.\n' +
                        '\n' +
                        'Кампания началась сразу после завершения Северовирджинской кампании, когда разбитая федеральная Вирджинская армия отступила в укрепления Вашингтона. Ли рассчитывал воспользоваться удачным моментом, чтобы вступить в Мэриленд, где он рассчитывал поддержать мэрилендских сецессионистов и добиться сецессии Мэриленда, или хотя бы пополнить армию за счёт местных жителей. 7 сентября Северовирджинская армия вступила во Фредерик. В это время командование федеральной армией снова принял Джордж Макклеллан, который слил остатки Вирджинской армии с Потомакской и начал наступление на Фредерик, рассчитывая сорвать наступательные планы противника. Не найдя сторонников во Фредерике, южане 10 сентября повернули на запад. Часть армии ушла за Южные горы, часть пошла на захват города Харперс-Ферри.</p>',
                },
                {
                    type: 'text_question',
                    title: 'Историческое сочинение',
                    question: '<p align="justify">Вам необходимо написать историческое сочинение об ОДНОМ из периодов истории России:<br>' +
                        'По историческим вопросам высказываются различные, часто противоречивые точки зрения. Ниже приведена одна из ' +
                        'противоречивых точек зрения. «Внутренняя политика Александра III способствовала прогрессивному развитию ' +
                        'социальной и экономической сфер общественной жизни». Используя исторические знания, приведите два аргумента, ' +
                        'которыми можно подтвердить данную точку зрения, и два аргумента, которыми можно опровергнуть её. При ' +
                        'изложении аргументов обязательно используйте исторические факты.   Ответ запишите в следующем виде. </p>' +
                        '<u>Аргументы в подтверждение:</u>' +
                        '<ol>' +
                        '<li>…</li>'+
                        '<li>…</li>' +
                        '</ol><br>' +
                        '<u>Аргументы в опровержение</u>:' +
                        '<ol>' +
                        '<li>…</li>' +
                        '<li>…</li>' +
                        '</ol>',
                    checked: true,
                    answer: '<p align="justify">В подтверждение :<br>' +
                        '<ol><li>Александр 3 значительно облегчил положение крестьян, ликвидировав временнообязанное состояние ,отменив подушную подать и понизив выпускные платежи</li>' +
                        '<li>Открытие при Александре 3 Крестьянского банка способствовало развитию крестьянских хозяйств и немного уменьшило проявления остатков феодализма</li>' +
                        'Облегчение положения рабочих в период правления А3 , путем принятия рабочего законодательства, запретившего труд подростков</li></ol>' +
                        '<br>' +
                        'В опровержение:<br>' +
                        '<ol><li> Ограничение А3 полномочий земств и создание института земских участковых начальников , что привело к тому , что менее зажиточным гражданам было сложнее попасть в земства и участвовать в их деятельности</li>' +
                        '<li>А3 своим "Циркуляром о кухаркиных детях " резко ограничил доступ к образованию для непривилегированных слоев общества</li></ol></p>',
                    accepted: true,
                },
                {
                    type: 'text',
                    title: 'Комментарий',
                    text: 'Это комментарий к ответу на вопрос выше',
                },
                {
                    type: 'text_question',
                    title: 'Историческое сочинение',
                    question: '<p align="justify">Вам необходимо написать историческое сочинение об ОДНОМ из периодов истории России:<br>' +
                        'По историческим вопросам высказываются различные, часто противоречивые точки зрения. Ниже приведена одна из ' +
                        'противоречивых точек зрения. «Внутренняя политика Александра III способствовала прогрессивному развитию ' +
                        'социальной и экономической сфер общественной жизни». Используя исторические знания, приведите два аргумента, ' +
                        'которыми можно подтвердить данную точку зрения, и два аргумента, которыми можно опровергнуть её. При ' +
                        'изложении аргументов обязательно используйте исторические факты.   Ответ запишите в следующем виде. </p>' +
                        '<u>Аргументы в подтверждение:</u>' +
                        '<ol>' +
                        '<li>…</li>'+
                        '<li>…</li>' +
                        '</ol><br>' +
                        '<u>Аргументы в опровержение</u>:' +
                        '<ol>' +
                        '<li>…</li>' +
                        '<li>…</li>' +
                        '</ol>',
                    checked: true,
                    answer: '<p align="justify">В подтверждение :<br>' +
                        '<ol><li>Александр 3 значительно облегчил положение крестьян, ликвидировав временнообязанное состояние ,отменив подушную подать и понизив выпускные платежи</li>' +
                        '<li>Открытие при Александре 3 Крестьянского банка способствовало развитию крестьянских хозяйств и немного уменьшило проявления остатков феодализма</li>' +
                        'Облегчение положения рабочих в период правления А3 , путем принятия рабочего законодательства, запретившего труд подростков</li></ol>' +
                        '<br>' +
                        'В опровержение:<br>' +
                        '<ol><li> Ограничение А3 полномочий земств и создание института земских участковых начальников , что привело к тому , что менее зажиточным гражданам было сложнее попасть в земства и участвовать в их деятельности</li>' +
                        '<li>А3 своим "Циркуляром о кухаркиных детях " резко ограничил доступ к образованию для непривилегированных слоев общества</li></ol></p>',
                    accepted: false,
                },
                {
                    type: 'text',
                    title: 'Комментарий',
                    text: 'Это комментарий к ответу на вопрос выше',
                },
                {
                    type: 'text_question',
                    title: 'Историческое сочинение',
                    question: '<p align="justify">Вам необходимо написать историческое сочинение об ОДНОМ из периодов истории России:<br>' +
                        'По историческим вопросам высказываются различные, часто противоречивые точки зрения. Ниже приведена одна из ' +
                        'противоречивых точек зрения. «Внутренняя политика Александра III способствовала прогрессивному развитию ' +
                        'социальной и экономической сфер общественной жизни». Используя исторические знания, приведите два аргумента, ' +
                        'которыми можно подтвердить данную точку зрения, и два аргумента, которыми можно опровергнуть её. При ' +
                        'изложении аргументов обязательно используйте исторические факты.   Ответ запишите в следующем виде. </p>' +
                        '<u>Аргументы в подтверждение:</u>' +
                        '<ol>' +
                        '<li>…</li>'+
                        '<li>…</li>' +
                        '</ol><br>' +
                        '<u>Аргументы в опровержение</u>:' +
                        '<ol>' +
                        '<li>…</li>' +
                        '<li>…</li>' +
                        '</ol>',
                    checked: true,
                    answer: '<p align="justify">В подтверждение :<br>' +
                        '<ol><li>Александр 3 значительно облегчил положение крестьян, ликвидировав временнообязанное состояние ,отменив подушную подать и понизив выпускные платежи</li>' +
                        '<li>Открытие при Александре 3 Крестьянского банка способствовало развитию крестьянских хозяйств и немного уменьшило проявления остатков феодализма</li>' +
                        'Облегчение положения рабочих в период правления А3 , путем принятия рабочего законодательства, запретившего труд подростков</li></ol>' +
                        '<br>' +
                        'В опровержение:<br>' +
                        '<ol><li> Ограничение А3 полномочий земств и создание института земских участковых начальников , что привело к тому , что менее зажиточным гражданам было сложнее попасть в земства и участвовать в их деятельности</li>' +
                        '<li>А3 своим "Циркуляром о кухаркиных детях " резко ограничил доступ к образованию для непривилегированных слоев общества</li></ol></p>',
                    accepted: null,
                },
                {
                    type: 'text',
                    title: 'Комментарий',
                    text: 'Это комментарий к ответу на вопрос выше',
                },
                {
                    type: 'text_question',
                    title: 'Историческое сочинение',
                    question: '<p align="justify">Вам необходимо написать историческое сочинение об ОДНОМ из периодов истории России:<br>' +
                        'По историческим вопросам высказываются различные, часто противоречивые точки зрения. Ниже приведена одна из ' +
                        'противоречивых точек зрения. «Внутренняя политика Александра III способствовала прогрессивному развитию ' +
                        'социальной и экономической сфер общественной жизни». Используя исторические знания, приведите два аргумента, ' +
                        'которыми можно подтвердить данную точку зрения, и два аргумента, которыми можно опровергнуть её. При ' +
                        'изложении аргументов обязательно используйте исторические факты.   Ответ запишите в следующем виде. </p>' +
                        '<u>Аргументы в подтверждение:</u>' +
                        '<ol>' +
                        '<li>…</li>'+
                        '<li>…</li>' +
                        '</ol><br>' +
                        '<u>Аргументы в опровержение</u>:' +
                        '<ol>' +
                        '<li>…</li>' +
                        '<li>…</li>' +
                        '</ol>',
                    checked: false,
                    answer: '',
                    accepted: null,
                },
                {
                    type: 'text',
                    title: 'Комментарий',
                    text: 'Это комментарий к ответу на вопрос выше',
                },
                {
                    type: 'checkbox_question',
                    title: 'Историческое сочинение',
                    question: '<p align="justify">Вам необходимо написать историческое сочинение об ОДНОМ из периодов истории России:<br>' +
                        'По историческим вопросам высказываются различные, часто противоречивые точки зрения. Ниже приведена одна из ' +
                        'противоречивых точек зрения. «Внутренняя политика Александра III способствовала прогрессивному развитию ' +
                        'социальной и экономической сфер общественной жизни». Используя исторические знания, приведите два аргумента, ' +
                        'которыми можно подтвердить данную точку зрения, и два аргумента, которыми можно опровергнуть её. При ' +
                        'изложении аргументов обязательно используйте исторические факты.   Ответ запишите в следующем виде. </p>' +
                        '<u>Аргументы в подтверждение:</u>' +
                        '<ol>' +
                        '<li>…</li>'+
                        '<li>…</li>' +
                        '</ol><br>' +
                        '<u>Аргументы в опровержение</u>:' +
                        '<ol>' +
                        '<li>…</li>' +
                        '<li>…</li>' +
                        '</ol>',
                    checked: false,
                    answer: [
                        {
                            'id': '1',
                            'variant': '123',
                            'truth': null,
                            'checked': null,
                        },
                        {
                            'id': '2',
                            'variant': '456',
                            'truth': null,
                            'checked': null,
                        },
                        {
                            'id': '3',
                            'variant': '789',
                            'truth': null,
                            'checked': null,
                        },
                        {
                            'id': '4',
                            'variant': '101112',
                            'truth': null,
                            'checked': null,
                        },
                    ],
                    accepted: null,
                },
                {
                    type: 'text',
                    title: 'Комментарий',
                    text: 'Это комментарий к ответу на вопрос выше',
                },
                {
                    type: 'checkbox_question',
                    title: 'Историческое сочинение',
                    question: '<p align="justify">Вам необходимо написать историческое сочинение об ОДНОМ из периодов истории России:<br>' +
                        'По историческим вопросам высказываются различные, часто противоречивые точки зрения. Ниже приведена одна из ' +
                        'противоречивых точек зрения. «Внутренняя политика Александра III способствовала прогрессивному развитию ' +
                        'социальной и экономической сфер общественной жизни». Используя исторические знания, приведите два аргумента, ' +
                        'которыми можно подтвердить данную точку зрения, и два аргумента, которыми можно опровергнуть её. При ' +
                        'изложении аргументов обязательно используйте исторические факты.   Ответ запишите в следующем виде. </p>' +
                        '<u>Аргументы в подтверждение:</u>' +
                        '<ol>' +
                        '<li>…</li>'+
                        '<li>…</li>' +
                        '</ol><br>' +
                        '<u>Аргументы в опровержение</u>:' +
                        '<ol>' +
                        '<li>…</li>' +
                        '<li>…</li>' +
                        '</ol>',
                    checked: true,
                    answer: [
                        {
                            'id': '1',
                            'variant': '123',
                            'truth': false,
                            'checked': true,
                        },
                        {
                            'id': '2',
                            'variant': '456',
                            'truth': true,
                            'checked': true,
                        },
                        {
                            'id': '3',
                            'variant': '789',
                            'truth': false,
                            'checked': false,
                        },
                        {
                            'id': '4',
                            'variant': '101112',
                            'truth': true,
                            'checked': false,
                        },
                    ],
                },
                {
                    type: 'text',
                    title: 'Комментарий',
                    text: 'Это комментарий к ответу на вопрос выше',
                },
                {
                    type: 'checkbox_question',
                    title: 'Историческое сочинение',
                    question: '<p align="justify">Вам необходимо написать историческое сочинение об ОДНОМ из периодов истории России:<br>' +
                        'По историческим вопросам высказываются различные, часто противоречивые точки зрения. Ниже приведена одна из ' +
                        'противоречивых точек зрения. «Внутренняя политика Александра III способствовала прогрессивному развитию ' +
                        'социальной и экономической сфер общественной жизни». Используя исторические знания, приведите два аргумента, ' +
                        'которыми можно подтвердить данную точку зрения, и два аргумента, которыми можно опровергнуть её. При ' +
                        'изложении аргументов обязательно используйте исторические факты.   Ответ запишите в следующем виде. </p>' +
                        '<u>Аргументы в подтверждение:</u>' +
                        '<ol>' +
                        '<li>…</li>'+
                        '<li>…</li>' +
                        '</ol><br>' +
                        '<u>Аргументы в опровержение</u>:' +
                        '<ol>' +
                        '<li>…</li>' +
                        '<li>…</li>' +
                        '</ol>',
                    checked: true,
                    answer: [
                        {
                            'id': '1',
                            'variant': '123',
                            'truth': true,
                            'checked': true,
                        },
                        {
                            'id': '2',
                            'variant': '456',
                            'truth': true,
                            'checked': true,
                        },
                        {
                            'id': '3',
                            'variant': '789',
                            'truth': false,
                            'checked': false,
                        },
                        {
                            'id': '4',
                            'variant': '101112',
                            'truth': false,
                            'checked': false,
                        },
                    ],
                },
                {
                    type: 'text',
                    title: 'Комментарий',
                    text: 'Это комментарий к ответу на вопрос выше',
                },
            ],
            elevation: 3,
            title_elevation: 24,
            debug_elevation: 0,
        } ),
    };
</script>

<style>
    /*@import "../assets/css/debug.css";*/
    @import "../assets/css/app.css";
</style>

