<template>
  <v-container>
    <v-snackbar v-model="show_message">
      {{ message }}
    </v-snackbar>
    <v-hover v-slot:default="{ hover }">
      <v-card
        @click="go_to_classes()"
        class="custom-debug"
        :elevation="hover ? settings.hover_elevation : settings.elevation"
        style="
          height: 300px;
          width: 368px;
          padding: 0px 15px 0 0;
          margin: 20px 0 -10px 0;
        "
      >
        <v-row
          style="
            padding: 0px 15px 0 0;
            margin: 0 0 -10px 0;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          <v-col cols="11">
            <div>
              <span
                class="d-inline-block text-truncate"
                style="max-width: 280px;"
              >
                {{ course.title }}
              </span>
            </div>
          </v-col>
          <v-col cols="1" style="padding: 5px 0px 0 0; margin: 0 0 0 0;">
            <v-btn
              icon
              @click.stop="buttonDeleteCourse()"
              :disabled="course.available"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div :style="gray_scale(course)">
          <v-img
            :src="course.image_url"
            style="
              height: 207px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              padding: 0 0 0 0;
              margin: 0 0 0 0;
              width: 368px;
            "
            class="custom-debug"
          />
        </div>
        <v-row
          style="
            padding: 0px 15px 0 0;
            margin: 0 0 -10px 0;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          <v-col cols="12">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div
                  style="
                    padding: 0px 15px 0 0;
                    margin: 0 0 0 0;
                    width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 330px;"
                  >
                    {{ course.description }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
    <CourseDelete
      :course="course"
      :openDialog="course_delete_dialog"
      :key="key_delete_dialog"
    />
  </v-container>
</template>

<script>
const file = 'components/iter2/courses/Card.vue'
const compColor = 'color: #9C27B0'
const compColorI = 'color: #ff00B0'
import CourseDelete from '../../course/CourseDelete'
import { mapGetters } from 'vuex'
export default {
  name: 'Card',
  components: { CourseDelete },
  props: ['course'],
  async created() {
    console.log(`%c${file} created this.settings %o`, compColor, this.settings)
    this.settings = this.get_settings
    console.log(`%c${file} created this.settings %o`, compColorI, this.settings)
  },
  computed: {
    ...mapGetters({
      get_settings: 'settings',
    }),
  },
  methods: {
    gray_scale: (course) => {
      if (course === undefined || course == null) {
        console.log(
          'components/course/CourseCard.vue  goToClasses this %o',
          this,
        )
        return ''
      }
      return !course.available ? `filter: grayscale(100%)` : ``
    },
    async go_to_classes() {
      console.log(
        `%c${file} go_to_classes  this.course %o`,
        compColorI,
        this.course,
      )
      await this.$router.push(`/v2/classes/${this.course._id}`)
      return
    },
    buttonDeleteCourse() {
      console.log(
        'Button Clicked event course_delete_dialog %o   key_delete_dialog %o',
        this.course_delete_dialog,
        this.key_delete_dialog,
      )

      this.course_delete_dialog = true
      this.key_delete_dialog++
    },
  },
  data: () => ({
    settings: null,
    hover: false,
    course_delete_dialog: false,
    key_delete_dialog: 1,

    message: '',
    show_message: false,
  }),
}
</script>

<style scoped></style>
