var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"custom-debug",attrs:{"width":"344","elevation":_vm.course_available(_vm.course)
                    ? hover
                        ? _vm.settings.hover_elevation
                        : _vm.settings.elevation
                    : _vm.settings.elevation}},[_c('a',{staticClass:"custom-debug custom-nav-button",style:(_vm.course_available(_vm.course)
                        ? "cursor: pointer"
                        : "cursor: auto"),on:{"click":function () { return _vm.goToClasses(_vm.course); }}},[_c('v-card-text',[_c('v-toolbar-title',{staticClass:"custom-debug subtitle-1",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.course === undefined ? "" : _vm.course.title)+" ")])],1),_c('div',{style:(_vm.gray_scale(_vm.course))},[_c('v-img',{staticClass:"custom-debug",staticStyle:{"height":"200px"},attrs:{"src":_vm.course === undefined ? '' : _vm.course.image_url}})],1),_c('v-card-text',{staticClass:"custom-debug d-flex text-lg-justify grey--text"},[_vm._v(" "+_vm._s(_vm.cut( _vm.course === undefined ? "" : _vm.course.description, _vm.max_length_description ))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-switch',{staticClass:"ma-2",attrs:{"label":_vm.course_state},on:{"change":function($event){return _vm.change_active(_vm.course, _vm.active)}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{staticClass:" ma-2 ",attrs:{"fab":"","small":"","color":"#ffc72c","disabled":_vm.active},on:{"click":function($event){$event.stopPropagation();_vm.dialog = _vm.ask_delete_course(_vm.course)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:" font-weight-regular grey--text"},[_vm._v(" Payment url:"),_c('br'),_c('a',{attrs:{"href":_vm.course.payment_url}},[_vm._v(" "+_vm._s(_vm.course === undefined ? "" : _vm.course.payment_url)+" ")])])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"Trial duration (min.)","loading":_vm.course.loading === undefined
                                ? false
                                : _vm.course.loading,"type":"number","append-outer-icon":"mdi-send"},on:{"click:append-outer":function($event){return _vm.sendMessage(_vm.course)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_vm._v(" Trial duration (minutes) or \"endless\" trial if not specified. ")])]},proxy:true}],null,true),model:{value:(_vm.course.trial_time),callback:function ($$v) {_vm.$set(_vm.course, "trial_time", $$v)},expression:"course.trial_time"}})],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline yellow darken-2"},[_vm._v("Delete course")]),_c('v-card-text',[_vm._v(" Attention course \""+_vm._s(this.cur_course_title)+"\" will be deleted. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.close_dialog = _vm.delete_course(_vm.course)}}},[_vm._v(" Agree ")])],1)],1)],1),_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"text-center"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }