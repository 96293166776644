<template>
    <v-container>
        <v-hover v-slot:default="{ hover }">
            <v-card
                width="344"
                class="custom-debug"
                :elevation="
                    course_available(course)
                        ? hover
                            ? settings.hover_elevation
                            : settings.elevation
                        : settings.elevation
                "
            >
                <a
                    @click="() => goToClasses(course)"
                    class="custom-debug custom-nav-button"
                    :style="
                        course_available(course)
                            ? `cursor: pointer`
                            : `cursor: auto`
                    "
                >
                    <v-card-text>
                        <v-toolbar-title
                            class="custom-debug subtitle-1"
                            style="color: black"
                        >
                            {{ course === undefined ? "" : course.title }}
                        </v-toolbar-title>
                    </v-card-text>
                    <div :style="gray_scale(course)">
                        <v-img
                            :src="course === undefined ? '' : course.image_url"
                            style="height: 200px;"
                            class="custom-debug"
                        />
                    </div>
                    <v-card-text
                        class="custom-debug d-flex text-lg-justify grey--text"
                    >
                        {{
                            cut(
                                course === undefined ? "" : course.description,
                                max_length_description
                            )
                        }}
                    </v-card-text>
                </a>
                <v-row>
                    <v-col cols="12" sm="9">
                        <v-switch
                            v-model="active"
                            class="ma-2"
                            :label="course_state"
                            @change="change_active(course, active)"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-btn
                            class=" ma-2 "
                            fab
                            small
                            color="#ffc72c"
                            :disabled="active"
                            @click.stop="dialog = ask_delete_course(course)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class=" font-weight-regular grey--text">
                            Payment url:<br />
                            <a v-bind:href="course.payment_url">
                                {{
                                    course === undefined
                                        ? ""
                                        : course.payment_url
                                }}
                            </a>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="course.trial_time"
                            outlined
                            dense
                            clearable
                            label="Trial duration (min.)"
                            :loading="
                                course.loading === undefined
                                    ? false
                                    : course.loading
                            "
                            type="number"
                            @click:append-outer="sendMessage(course)"
                            append-outer-icon="mdi-send"
                        >
                            <template v-slot:prepend>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">
                                            mdi-help-circle-outline
                                        </v-icon>
                                    </template>
                                    Trial duration (minutes) or "endless" trial
                                    if not specified.
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-hover>
        <v-dialog v-model="dialog" max-width="290">
            <v-card>
                <v-card-title class="headline yellow darken-2"
                    >Delete course</v-card-title
                >

                <v-card-text>
                    Attention course "{{ this.cur_course_title }}" will be
                    deleted.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="dialog = false">
                        Disagree
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="close_dialog = delete_course(course)"
                    >
                        Agree
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Loading class="text-center" v-show="loading" />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "../Loading";
export default {
    name: "CourseCard",
    components: { Loading },

    props: ["course"],
    watch: {
        // эта функция запускается при любом изменении вопроса
        close_dialog: function() {
            console.log("close_dialog");

            this.dialog = false;
        }
    },
    created() {
        this.settings = this.$store.state.settings;
        this.active = this.$props.course.available;
        // console.log(
        //     "components/course/CourseCard.vue this %o ",
        //     this
        // );
    },
    // beforeCreate() {
    //     console.log("components/course/CourseCard.vue beforeCreate 1");
    // },
    // beforeMount() {
    //     console.log("components/course/CourseCard.vue beforeMount 1");
    // },
    // mounted() {
    //     console.log("components/course/CourseCard.vue mounted 1");
    // },
    // beforeUpdate() {
    //     console.log("components/course/CourseCard.vue beforeUpdate 1");
    // },
    // updated() {
    //     console.log("components/course/CourseCard.vue updated 1");
    // },
    computed: {
        progress() {
            return Math.min(100, this.value.length * 10);
        },
        color() {
            return ["error", "warning", "success"][
                Math.floor(this.progress / 40)
            ];
        },
        ...mapGetters({
            sel_course: "SELECTED_COURSE"
        }),
        course_state() {
            // console.log(" course_state")
            return this.$props.course.available
                ? "Active course"
                : "Inactive course";
        }

        // active(){
        //     console.log(" active")
        //     return this.$props.course.available
        //     }
    },
    methods: {
        async sendMessage(course) {
            console.log("CourseCard.vue sendMessage course: %o", course);
            course.loading = true;

            console.log(
                "CourseCard.vue UPDATE_COURSE_TRIAL_TIME befor course: %o",
                course
            );
            await this.UPDATE_COURSE_TRIAL_TIME(course);
            console.log(
                "CourseCard.vue UPDATE_COURSE_TRIAL_TIME after course: %o",
                course
            );
            course.loading = false;

        },
        ...mapActions([
            "UPDATE_COURSE_STATE",
            "MARK_COURSE_FOR_DELETE",
            "UPDATE_COURSE_TRIAL_TIME"
        ]),
        change_active(course, state) {
            // console.log(" sel_course: %o  active: %o", course, state)
            this.UPDATE_COURSE_STATE({ course_id: course._id, active: state });
            return;
        },
        ask_delete_course(course) {
            // this.UPDATE_COURSE_STATE({ course_id: course._id, active: state });
            console.log("course: %o", course);
            this.cur_course_title = course.title;
            return true;
        },
        async delete_course(course) {
            // this.UPDATE_COURSE_STATE({ course_id: course._id, active: state });
            console.log("delete_course befor course: %o", course);
            await this.MARK_COURSE_FOR_DELETE(course);
            console.log("delete_course after course: %o", course);
            return false;
        },
        async delete_course1(course) {
            // this.UPDATE_COURSE_STATE({ course_id: course._id, active: state });
            console.log("delete_course befor course: %o", course);
            // await this.MARK_COURSE_FOR_DELETE(course);
            // console.log("delete_course after course: %o", course);
            this.close_dialog = false;
            return false;
        },
        course_available: course => {
            if (course === undefined || course == null) {
                return false;
            }

            return course.available;
        },
        gray_scale: course => {
            if (course === undefined || course == null) {
                console.log(
                    "components/course/CourseCard.vue  goToClasses this %o",
                    this
                );
                return "";
            }
            return !course.available ? `filter: grayscale(100%)` : ``;
        },
        goToClasses(course) {
            // console.log(
            //     "components/course/CourseCard.vue  goToClasses props.course %o    course %o",
            //     this.$props.course,
            //     course
            // );
            this.$router.push({ path: `/course/${course._id}` });
        },
        cut(text) {
            if (!!text && text.length > this.max_length_description) {
                return text.slice(0, this.max_length_description) + "...";
            }
            return text;
        },
        class_status(cl) {
            return (100 * cl.solved_classes) / cl.amount_classes;
        }
    },
    data: () => ({
        max_length_description: 230,
        loading: false,
        settings: null,
        hover: false,
        active: false,
        dialog: false,
        close_dialog: false,
        cur_course_title: null,
        message: "223344"
    })
};
</script>

<style scoped>
@import "../../assets/css/navigation_buttons.css";
</style>
