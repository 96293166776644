import * as cnst from "../config/constants";

const file = "/store/index.js";
const compColor = "color: #9C27B0";
console.warn(`${file} START 1`);
const config = grader_config();
console.log(`${file}` + " START 2--||-- config: %o", config);
import Axios from "axios";
console.warn(`${file} START 2`);
import Vue from "vue";
import Vuex from "vuex";
// import router from "../router"
import mock_classes from "../plugins/mocks/classes";
import mock_courses from "../plugins/mocks/courses";
import logger from "../plugins/logger";
console.warn(`${file} START 3`);

Vue.use(Vuex);
console.warn(`${file} START 4`);

let BACK_ADMIN_PUBLIC = config.VUE_APP_BACK_ADMIN_PUBLIC;
let BACK_ADMIN_SCHEMA = config.VUE_APP_BACK_ADMIN_SCHEMA;
let BACK_ADMIN_HOST = config.VUE_APP_BACK_ADMIN_HOST;
let BACK_ADMIN_PORT = config.VUE_APP_BACK_ADMIN_PORT;
let admin_back = `${BACK_ADMIN_SCHEMA}://${BACK_ADMIN_HOST}:${BACK_ADMIN_PORT}`;
console.log(`${file} START 5  admin_back %o`, admin_back);
if (BACK_ADMIN_PUBLIC.toLocaleLowerCase() === "true") {
    admin_back = `${BACK_ADMIN_SCHEMA}://${BACK_ADMIN_HOST}`;
}
let TILDA_SERVICE_PORT = config.VUE_APP_TILDA_SERVICE_PORT;
let TILDA_SERVICE_HOST = config.VUE_APP_TILDA_SERVICE_HOST;
let TILDA_SERVICE_SCHEMA = config.VUE_APP_TILDA_SERVICE_SCHEMA;
let TILDA_SERVICE_PUBLIC = config.VUE_APP_TILDA_SERVICE_PUBLIC;
let TILDA_SERVICE_API_KEY = config.VUE_APP_TILDA_SERVICE_API_KEY;
let tilda_back = `${TILDA_SERVICE_SCHEMA}://${TILDA_SERVICE_HOST}:${TILDA_SERVICE_PORT}`;
console.log(`${file} START 6.1  admin_back %o`, tilda_back);
if (TILDA_SERVICE_PUBLIC.toLocaleLowerCase() === "true") {
    tilda_back = `${TILDA_SERVICE_SCHEMA}://${TILDA_SERVICE_HOST}`;
}
console.log(`${file} START 6.2  admin_back %o`, admin_back);
// console.log("store/index.js  admin_back=%o", admin_back);

export default new Vuex.Store({
    state: {
        settings: {
            loading: false,
            elevation: 3,
            debug_elevation: 0,
            hover_elevation: 12,
            title_elevation: 3,
            background_color: "#efeeee",
            general_color: "#ffc72c",
            back_general_color: "#ffda73",
            light_general_color: "#fffae6",
            image_placeholder: "../assets/images/fless-pro.png",

            color_type_block: {
                text: "#ffae60",
                video: "#D1C400",
                numeric: "#D100E9",
                comment: "#00C4E9",
                radiobutton: "#D188E9",
                text_question_open: "#0188E9",
                checkbox: "#018800",
                text_question_close: "#f188E9",
            },
            icon_type_block: {
                text: "mdi-text", // mdi-text-box
                video: "mdi-video",
                numeric: "mdi-numeric",
                comment: "mdi-comment",
                radiobutton: "mdi-check-circle",
                text_question_open: "mdi-pen",
                checkbox: "mdi-checkbox-marked",
                text_question_close: "mdi-pencil-box-outline",
            },
        },
        status: "",
        token: localStorage.getItem("token") || "",
        users: [],
        user: {},
        courses: [], // Все курсы, которые получены с бэкенда
        courses_user: [], // Все курсы, с информайией о крсах юзера
        course: {}, // Текущий выбранный курс
        selected_course_id: null,
        classes: [], // Классы, для текущего курса (каждый раз запрашиваются с бэка при сбросе course_id)
        statuses: [], // Статусы, для текущего юзера
        class: {
            // Текущий выбранный класс
            class_id: null,
        },
        selected_course: null,
        selected_class: null,
        selected_block: null,
        blocks: [], // Все блоки текущего выбранного класса
        class_tmp: [], // список блоков класса
        tasks: [], // Все доступные для выбранного класса (каждый раз запрашиваются с бэка при сбросе class_id)
        error: null,
        response: null,
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status,
        USERS: (state) => state.users,
        GET_USER: (state) => state.user,
        settings: (state) => state.settings,
        COURSES: (state) => state.courses,
        COURSES_USER: (state) => state.courses_user,
        COURSE: (state) => state.course,
        STATUSES: (state) => state.statuses,
        COURSE_CLASSES: (state) => state.classes,
        SELECTED_BLOCK: (state) => state.selected_block,
        SELECTED_CLASS: (state) => state.selected_class,
        SELECTED_COURSE: (state) => state.selected_course,
        SELECTED_COURSE_ID: (state) => state.selected_course_id,
        getSelectedClass: (state) => state.selected_class,
        getSelectedCourse: (state) => state.selected_course,
        BLOCKS: (state) => state.blocks,
        getToken: (state) => state.token,
        ERROR: (state) => state.error,
        RESPONSE: (state) => state.response,
    },
    mutations: {
        loading: (state, payload) => {
            state.settings.loading = payload;
        },
        classes: (state, payload) => {
            state.classes = payload;
        },
        course: (state, payload) => {
            state.course = payload;
        },
        courses: (state, payload) => {
            state.courses = payload;
        },
        SET_TOKEN: (state, payload) => {
            state.token = payload;
        },
        SET_USER: (state, payload) => {
            state.user = payload;
        },
        SET_USERS: (state, payload) => {
            state.users = payload;
        },
        SET_USER_STATE: (state, payload) => {
            state.users[payload.index].state = payload.state;
            state.users[payload.index]._etag = payload.etag;
        },
        SET_COURSES: (state, payload) => {
            state.courses = payload;
        },
        SET_COURSES_USER: (state, payload) => {
            state.courses_user = JSON.parse(JSON.stringify(payload));
        },
        REFRESH_COURSES_USER: (state) => {
            state.courses_user = JSON.parse(JSON.stringify(state.courses_user));
            state.courses_user.splice(0, state.courses_user.length);
        },
        SET_COURSE: (state, payload) => {
            // console.log("SET_COURSE mutations payload: %o", payload);
            state.course = payload;
        },
        SET_STATUSES: (state, payload) => {
            state.statuses = payload;
        },
        SET_COURSE_STATE: (state, payload) => {
            // console.log("SET_COURSE_STATE mutations payload: %o", payload);
            let index = state.courses.findIndex((el) => el._id === payload.id);
            if (index < 0) {
                let msg = `store function: changeCourse. not found course with course_id: ${payload.id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            // debugger;
            console.log(
                "SET_COURSE_STATE +++++++ mutations befor change state.courses: %o index: %o",
                state.courses,
                index
            );

            state.courses[index].available = payload.available;
            state.courses[index]._etag = payload.etag;
            console.log(
                "SET_COURSE_STATE ******* mutations after change state.courses: %o index: %o",
                state.courses,
                index
            );
        },
        SET_COURSE_USER_INFO: (state, payload) => {
            let index = state.courses_user.findIndex(
                (el) => el._id === payload.course_id
            );
            if (index < 0) {
                let msg = `store function: changeCourse. not found course with course_id: ${payload.course_id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            state.courses_user[index][payload.field] = JSON.parse(
                JSON.stringify(payload[payload.field])
            );
        },
        DELETE_COURSE: (state, payload) => {
            // console.log("SET_COURSE_STATE mutations payload: %o", payload);
            let index = state.courses.findIndex((el) => el._id === payload.id);
            if (index < 0) {
                let msg = `store function: changeCourse. not found course with course_id: ${payload.id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            // debugger;
            console.log(
                "DELETE_COURSE +++++++ mutations befor change state.courses: %o index: %o",
                state.courses,
                index
            );
            // state.courses[index].available = payload.available;
            // state.courses[index]._etag = payload.etag;
            state.courses.splice(index, 1);
            console.log(
                "DELETE_COURSE ******* mutations after change state.courses: %o index: %o",
                state.courses,
                index
            );
        },
        SET_COURSE_TRIAL_TIME: (state, payload) => {
            console.log("SET_COURSE_STATE mutations payload: %o", payload);
            let index = state.courses.findIndex((el) => el._id === payload.id);
            // debugger;
            if (index < 0) {
                let msg = `store function: SET_COURSE_TRIAL_TIME. not found course with course_id: ${payload.id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            // debugger;
            console.log(
                "SET_COURSE_TRIAL_TIME +++++++ mutations befor change state.courses: %o index: %o",
                state.courses,
                index
            );
            state.courses[index].trial_time = payload.trial_time;
            state.courses[index]._etag = payload._etag;
            state.courses[index].loading = false;
            // state.$set(state.items, index, newValue)
            // state.courses.splice(index, 1)
            console.log(
                "SET_COURSE_TRIAL_TIME ******* mutations after change state.courses: %o index: %o",
                state.courses,
                index
            );
        },
        SET_USER_COURSES: (state, payload) => {
            // console.log("SET_USER_COURSES mutations payload: %o", payload);
            let index = state.users.findIndex((el) => el._id === payload.id);
            if (index < 0) {
                let msg = `store function: SET_USER_COURSES. not found user with user_id: ${payload.id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            // debugger;
            // console.log(
            //     "SET_USER_COURSES +++++++ mutations befor change state.courses: %o index: %o",
            //     state.users,
            //     index
            // );
            state.users[index].course_ids = payload.course_ids;
            state.users[index]._etag = payload.etag;
            // console.log(
            //     "SET_USER_COURSES ******* mutations after change state.courses: %o index: %o",
            //     state.users,
            //     index
            // );
        },
        SET_CLASS: (state, payload) => {
            // console.log("SET_CLASS mutations payload: %o", payload);
            state.class = payload;
        },
        SET_CLASSES: (state, payload) => {
            // console.log("SET_CLASSES mutations payload: %o", payload);
            state.classes = payload;
        },
        SET_SELECTED_BLOCK: (state, payload) => {
            // console.log("SET_SELECTED_BLOCK mutations payload: %o", payload);
            state.selected_block = payload;
        },
        SET_SELECTED_CLASS: (state, payload) => {
            // console.log("SET_SELECTED_CLASS mutations payload: %o", payload);
            state.selected_class = payload;
        },
        SET_SELECTED_COURSE: (state, payload) => {
            // console.log("SET_SELECTED_COURSE mutations payload: %o", payload);
            state.selected_course = payload;
            state.selected_class = null;
            state.blocks = [];
            state.classes = [];
        },
        SET_SELECTED_COURSE_ID: (state, payload) => {
            // console.log(
            //     "SET_SELECTED_COURSE_ID mutations payload: %o",
            //     payload
            // );

            state.selected_course_id = payload;
        },
        SET_BLOCKS: (state, payload) => {
            // console.log("SET_BLOCKS mutations payload:  %o", payload);
            state.blocks = payload;
        },
        SET_ERROR: (state, payload) => {
            // console.log("SET_ERROR mutations payload:  %o", payload);
            state.error = payload;
        },
        CLEAR_ERROR: (state) => {
            // console.log("CLEAR_ERROR mutations ");
            state.error = null;
        },
        SET_RESPONSE: (state, payload) => {
            // console.log("SET_RESPONSE mutations payload:  %o", payload);
            state.response = payload;
        },
        CLEAR_RESPONSE: (state) => {
            // console.log("CLEAR_RESPONSE mutations p");
            state.response = null;
        },
        LOGOUT: (state) => {
            // console.log("LOGOUT actions 1 state=%o", state);
            localStorage.setItem("token", "");
            state.isLoggedIn = false;
            state.token = "";
        },
        SET_COURSE_TITLE_DESCRIPTION: (state, payload) => {
            console.log(
                "SET_COURSE_TITLE_DESCRIPTION mutations payload: %o",
                payload
            );
            let index = state.courses.findIndex((el) => el._id === payload.id);
            // debugger;
            if (index < 0) {
                let msg = `store function: SET_COURSE_TITLE_DESCRIPTION. not found course with course_id: ${payload.id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            // debugger;
            console.log(
                "SET_COURSE_TITLE_DESCRIPTION +++++++ mutations befor change state.courses: %o index: %o",
                state.courses,
                index
            );
            state.courses[index].description = payload.description;
            state.courses[index].title = payload.title;
            state.courses[index]._etag = payload._etag;
            // state.$set(state.items, index, newValue)
            // state.courses.splice(index, 1)
            console.log(
                "SET_COURSE_TITLE_DESCRIPTION ******* mutations after change state.courses: %o index: %o course: %o",
                state.courses,
                index,
                state.courses[index]
            );
        },
        SET_CLASS_TITLE_DESCRIPTION: (state, payload) => {
            console.log(
                "SET_CLASS_TITLE_DESCRIPTION mutations payload: %o",
                payload
            );
            let index = state.classes.findIndex((el) => el._id === payload.id);
            // debugger;
            if (index < 0) {
                let msg = `store function: SET_CLASS_TITLE_DESCRIPTION. not found course with classes_id: ${payload.id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            // debugger;
            console.log(
                "SET_CLASS_TITLE_DESCRIPTION +++++++ mutations befor change state.classes: %o index: %o",
                state.classes,
                index
            );
            state.classes[index].description = payload.description;
            state.classes[index].title = payload.title;
            state.classes[index]._etag = payload._etag;
            // state.$set(state.items, index, newValue)
            // state.courses.splice(index, 1)
            console.log(
                "SET_CLASS_TITLE_DESCRIPTION ******* mutations after change state.classes: %o index: %o course: %o",
                state.classes,
                index,
                state.classes[index]
            );
        },
        SET_BLOCK_ATTRIBUTES: (state, payload) => {
            console.log("SET_BLOCK_ATTRIBUTES mutations payload: %o", payload);
            let index = state.blocks.findIndex((el) => el._id === payload._id);
            // debugger;
            if (index < 0) {
                let msg = `store function: SET_CLASS_TITLE_DESCRIPTION. not found course with classes_id: ${payload.id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            // debugger;
            console.log(
                "SET_BLOCK_ATTRIBUTES +++++++ mutations befor change state.classes: %o index: %o",
                state.blocks,
                index
            );
            state.blocks[index].text = payload.text;
            state.blocks[index].title = payload.title;
            state.blocks[index]._etag = payload._etag;
            state.blocks[index].block_type = payload.block_type;
            if ("variants" in payload) {
                state.blocks[index].variants = JSON.parse(
                    JSON.stringify(payload.variants)
                );
            }
            // state.$set(state.items, index, newValue)
            // state.courses.splice(index, 1)
            console.log(
                "SET_BLOCK_ATTRIBUTES ******* mutations after change state.classes: %o index: %o course: %o",
                state.blocks,
                index,
                state.blocks[index]
            );
        },
    },
    actions: {
        GET_TOKEN: async (context) => {
            // debugger
            // console.log("GET_TOKEN actions 1 payload=%o", payload);
            let token_loaded = null;
            await Axios.post(admin_back + "/token")
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "GET_TOKEN actions 2.1 befor GET_TOKEN.length=",
                    //     response.data.length
                    // );
                    token_loaded = response.data;
                    // console.log(
                    //     "GET_TOKEN actions 2.1 befor GET_TOKEN.length=",
                    //     response.data.length
                    // );
                    localStorage.setItem("token", token_loaded);
                    context.commit("SET_TOKEN", token_loaded);
                })
                .catch((error) => {
                    // debugger
                    console.log("GET_TOKEN actions ERROR");
                    context.commit("SET_CERT_ERROR", error);
                });
        },

        LOGIN: async (context, payload) => {
            // debugger
            // console.log("LOGIN actions 1 payload=%o", payload);
            // console.log("LOGIN actions 2 payload=%o", payload.data);
            var postData = {
                username: payload.username,
                password: payload.password,
            };
            let token_loaded = null;
            const qs = require("qs");
            await Axios.post(admin_back + "/login", qs.stringify(postData))
                .then((response) => {
                    //  debugger
                    // console.log("LOGIN actions 2.1 befor LOGIN");
                    token_loaded = response.data.access_token;
                    // console.log(
                    //     "LOGIN actions 2.1.1 befor set token token_loaded %o",
                    //     token_loaded
                    // );
                    // console.log(
                    //     "LOGIN actions 2.1.2 befor set token context %o",
                    //     context
                    // );
                    localStorage.setItem("token", token_loaded);
                    // console.log(
                    //     "LOGIN actions 2.1.3 befor set token context %o",
                    //     context
                    // );
                    context.commit("SET_TOKEN", token_loaded);
                    // console.log(
                    //     "LOGIN actions 2.1.4 befor set token context %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "LOGIN actions 2.2 befor clear token error: %o",
                        error
                    );
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                    // localStorage.setItem("token", "");
                    // context.commit("SET_TOKEN", "");
                });
        },

        USERS: async (context) => {
            // console.log("USERS actions 1 payload");
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/users", headers)
                .then((response) => {
                    //  debugger
                    // console.log("USERS actions 2.1 befor USERS");
                    let users = response.data._items;
                    // console.log(
                    //     "USERS actions 2.1.1 befor set users %o",
                    //     users
                    // );
                    // console.log(
                    //     "USERS actions 2.1.2 befor set users context %o",
                    //     context
                    // );
                    context.commit("SET_USERS", users);
                    // console.log(
                    //     "USERS actions 2.1.4 befor set token context %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "USERS actions 2.2 befor clear token error: %o",
                        error
                    );
                    context.commit("SET_USERS", []);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        ADD_USER: async (context) => {
            // debugger
            // console.log("USERS actions 1 payload");
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/users", headers)
                .then((response) => {
                    //  debugger
                    // console.log("USERS actions 2.1 befor USERS");
                    let users = response.data._items;
                    // console.log(
                    //     "USERS actions 2.1.1 befor set users %o",
                    //     users
                    // );
                    // console.log(
                    //     "USERS actions 2.1.2 befor set users context %o",
                    //     context
                    // );
                    context.commit("SET_USERS", users);
                    // console.log(
                    //     "USERS actions 2.1.4 befor set token context %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "USERS actions 2.2 befor clear token error: %o",
                        error
                    );
                    context.commit("SET_USERS", []);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        UPDATE_USER_COURSES: async (context, payload) => {
            // UPDATE_USER_COURSES
            // console.log("UPDATE_USER_COURSES actions 1 payload %o", payload);
            let user = context.state.users.filter(
                (el) => el._id === payload.user_id
            );
            // console.log("UPDATE_USER_COURSES actions 1 user %o", user);

            let data = {
                course_ids: payload.courses,
                etag: user[0]._etag,
            };
            // console.log("UPDATE_USER_COURSES actions 1 data=%o ", data);
            // debugger;
            await Axios.patch(
                admin_back + "/usercourses/" + user[0]._id,
                data,
                {
                    // data: data
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Bearer " + context.state.token,
                    },
                }
            )
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "UPDATE_USER_COURSES actions 2.1 befor response.data.length=%o data=%o",
                    //     response.data.length,
                    //     response.data
                    // );
                    context.commit("SET_USER_COURSES", {
                        course_ids: response.data.user_courses.course_ids,
                        etag: response.data.user_courses.user["_etag"],
                        id: response.data.user_courses.user["_id"],
                    });
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "UPDATE_USER_COURSES actions 2.2 befor SET_CERT_ERROR error %o",
                        error
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        ADD_USER_TO_COURSE: async (context, payload) => {
            // UPDATE_USER_COURSES

            let data = {
                course_id: payload.course_id,
                user_id: payload.user_id,
                paid: payload.paid,
            };
            console.log("ADD_USER_TO_COURSE actions 1 data=%o ", data);
            console.log(`%c${file} ADD_USER_TO_COURSE  %o  `, cnst.fmt_red, {
                this: this,
                payload,
                context,
            });
            if (payload.paid) {
                let data_payment = `course_id=${payload.course_id}&email=${context.state.user.email}`;

                console.log(
                    `%c${file} ADD_USER_TO_COURSE paid %o  `,
                    cnst.fmt_red,
                    {
                        this: this,
                        payload,
                        context,
                        tilda_back,
                        data_payment,
                    }
                );
                await Axios.post(tilda_back + "/payment", data_payment, {
                    // data: data
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        access_token: TILDA_SERVICE_API_KEY,
                    },
                })
                    .then((response) => {
                        console.log(
                            "ADD_USER_TO_COURSE actions payment 2.1 befor response.data.length=%o data=%o",
                            response.data.length,
                            response.data
                        );
                        // context.commit("SET_USER_COURSES", {
                        //     course_ids: response.data.user_courses.course_ids,
                        //     etag: response.data.user_courses.user["_etag"],
                        //     id: response.data.user_courses.user["_id"]
                        // });
                    })
                    .catch((error) => {
                        // debugger
                        console.log(
                            "ADD_USER_TO_COURSE actions payment 2.2 befor SET_CERT_ERROR error %o",
                            error
                        );
                        context.commit("SET_ERROR", error);
                        if (error.response.status == 401) {
                            context.commit("LOGOUT", "");
                        }
                    });
                return;
            }
            // debugger;
            await Axios.post(admin_back + "/course/user:add", data, {
                // data: data
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            })
                .then((response) => {
                    console.log(
                        "ADD_USER_TO_COURSE actions 2.1 befor response.data.length=%o data=%o",
                        response.data.length,
                        response.data
                    );
                    // context.commit("SET_USER_COURSES", {
                    //     course_ids: response.data.user_courses.course_ids,
                    //     etag: response.data.user_courses.user["_etag"],
                    //     id: response.data.user_courses.user["_id"]
                    // });
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "ADD_USER_TO_COURSE actions 2.2 befor SET_CERT_ERROR error %o",
                        error
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        COURSE_OPEN: async (context, payload) => {
            // COURSE_OPEN

            let data = {
                course_id: payload.course_id,
                user_email: payload.user_email,
            };
            // console.log("COURSE_OPEN actions 1 data=%o ", data);
            // debugger;
            await Axios.post(admin_back + "/user/course:open", data, {
                // data: data
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            })
                .then((response) => {
                    console.log(
                        "COURSE_OPEN actions 2.1 befor response.data.length=%o data=%o",
                        response.data.length,
                        response.data
                    );
                    // context.commit("SET_USER_COURSES", {
                    //     course_ids: response.data.user_courses.course_ids,
                    //     etag: response.data.user_courses.user["_etag"],
                    //     id: response.data.user_courses.user["_id"]
                    // });
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "COURSE_OPEN actions 2.2 befor SET_CERT_ERROR error %o",
                        error
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        COURSES: async (context) => {
            // debugger
            // console.log("COURSES actions 1 payload");
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            context.commit("CLEAR_ERROR");

            await Axios.get(admin_back + "/courses", headers)
                .then((response) => {
                    //  debugger
                    // console.log("COURSES actions 2.1 befor USERS");
                    let items = response.data._items;
                    // console.log(
                    //     "COURSES actions 2.1.1 befor set users %o",
                    //     items
                    // );
                    // console.log(
                    //     "COURSES actions 2.1.2 befor set users context %o",
                    //     context
                    // );
                    context.commit("SET_COURSES", items);
                    // console.log(
                    //     "COURSES actions 2.1.4 befor context %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log("COURSES actions 2.2  error: %o", error);
                    context.commit("SET_COURSES", []);
                    context.commit("SET_ERROR", {
                        status: error.status,
                        statusText: error.statusText,
                    });
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        COURSE_USER_META: async (context, payload) => {
            // debugger
            // console.log("COURSE_USER_META actions 1 payload %o", payload);
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            context.commit("CLEAR_ERROR");
            let meta = { meta: { progress: 0, finished: false } };

            await Axios.get(
                admin_back +
                    `/course/${payload.course_id}/meta/?user_id=${payload.user_id}`,
                headers
            )
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "COURSE_USER_META actions 2. response %o",
                    //     response
                    // );
                    // let items = response.data._items;
                    // console.log(
                    //     "COURSES actions 2.1.1 befor set users %o",
                    //     items
                    // );
                    // console.log(
                    //     "SET_COURSE_USER_INFO befor commit  good %o",
                    //     context
                    // );

                    context.commit("SET_COURSE_USER_INFO", {
                        course_id: payload.course_id,
                        field: "meta",
                        meta: response.data.meta,
                    });
                    // context.commit("SET_COURSES", items);
                    // console.log(
                    //     "COURSES actions 2.1.4 befor context %o",
                    //     context
                    // );
                    meta = JSON.parse(JSON.stringify(response.data));
                })
                .catch((error) => {
                    // debugger
                    // console.log("COURSES actions 2.2  error: %o", error);
                    // console.log(
                    //     "SET_COURSE_USER_INFO befor commit  bad %o",
                    //     context
                    // );
                    meta["meta"]["error"] = error;
                    context.commit("SET_COURSE_USER_INFO", {
                        course_id: payload.course_id,
                        field: "meta",
                        meta: meta,
                    });
                    // context.commit("SET_ERROR", {
                    //     status: error.status,
                    //     statusText: error.statusText
                    // });
                    // if (error.response.status == 401) {
                    //     context.commit("LOGOUT", "");
                    // }
                });
            return meta;
        },
        UPDATE_COURSE_ATTRIBUTES: async (context, payload) => {
            // UPDATE_USER_COURSES

            let data = {
                id: payload.id,
                etag: payload.etag,
                title: payload.title,
                description: payload.description,
                image_url: payload.image_url,
                payment_url: payload.payment_url,
            };
            // console.log("UPDATE_USER_COURSES actions 1 data=%o ", data);
            console.log(
                `%c${file}  UPDATE_COURSE_ATTRIBUTES 1  payload=%o data=%o`,
                payload,
                data
            );
            await Axios.put(admin_back + "/course", data, {
                // data: data
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            })
                .then((response) => {
                    console.log(
                        `%c${file}  UPDATE_COURSE_ATTRIBUTES 2.1 befor response.data.length=%o data=%o`,
                        response.data.length,
                        response.data
                    );

                    context.commit("SET_COURSE_TITLE_DESCRIPTION", {
                        id: data.id,
                        _etag: response.data.course["_etag"],
                        title: data["title"],
                        description: data["description"],
                        image_url: data["image_url"],
                        payment_url: data["payment_url"],
                    });
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "UPDATE_USER_COURSES actions 2.2 befor SET_CERT_ERROR error %o",
                        error
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        UPDATE_CLASS_ATTRIBUTES: async (context, payload) => {
            // UPDATE_USER_COURSES

            let data = {
                id: payload.id,
                etag: payload.etag,
                title: payload.title,
                description: payload.description,
            };
            // console.log("UPDATE_USER_COURSES actions 1 data=%o ", data);
            console.log(
                `%c${file}  UPDATE_CLASS_ATTRIBUTES 1  payload=%o data=%o`,
                payload,
                data
            );
            await Axios.put(admin_back + "/class", data, {
                // data: data
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            })
                .then((response) => {
                    console.log(
                        `%c${file}  UPDATE_CLASS_ATTRIBUTES 2.1 befor response.data.length=%o data=%o`,
                        response.data.length,
                        response.data
                    );

                    context.commit("SET_CLASS_TITLE_DESCRIPTION", {
                        id: data.id,
                        _etag: response.data["etag"],
                        title: response.data["title"],
                        description: response.data["description"],
                    });
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "UPDATE_CLASS_ATTRIBUTES actions 2.2 befor SET_CERT_ERROR error %o",
                        error
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        USER_STATUSES: async (context, payload) => {
            // debugger
            // console.log("COURSES actions 1 payload");
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(
                admin_back + "/user/" + payload + "/status",
                headers
            )
                .then((response) => {
                    //  debugger
                    // console.log("USER_STATUSES actions 2.1 befor USERS");
                    let items = response.data._items;
                    // console.log(
                    //     "USER_STATUSES actions 2.1.2 befor set users context %o",
                    //     context
                    // );
                    context.commit("SET_STATUSES", items);
                    // console.log(
                    //     "COURSES actions 2.1.4 befor context %o",
                    //     context
                    // );
                    return;
                })
                .catch((error) => {
                    // debugger
                    console.log("USER_STATUSES actions 2.2  error: %o", error);
                    context.commit("SET_STATUSES", []);
                    // context.commit("SET_COURSES", []);
                    // if (error.response.status == 401) {
                    //     context.commit("LOGOUT", "");
                    // }
                });
        },
        USER: async (context, payload) => {
            // debugger
            console.log(`%c${file}  USER 1  payload %o `, compColor, payload);
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/user/" + payload, headers)
                .then((response) => {
                    //  debugger
                    // console.log("USER_STATUSES actions 2.1 befor USERS");
                    console.log(
                        `%c${file}  USER 2  response %o `,
                        compColor,
                        response
                    );
                    let user = JSON.parse(JSON.stringify(response.data));
                    console.log(`%c${file}  USER 3  user %o `, compColor, user);
                    context.commit("SET_USER", user);
                    // console.log(
                    //     "COURSES actions 2.1.4 befor context %o",
                    //     context
                    // );
                    return;
                })
                .catch((error) => {
                    // debugger
                    console.log("USER_STATUSES actions 2.2  error: %o", error);
                    context.commit("SET_STATUSES", []);
                    // context.commit("SET_COURSES", []);
                    // if (error.response.status == 401) {
                    //     context.commit("LOGOUT", "");
                    // }
                });
        },
        COURSE: async (context, payload) => {
            // debugger
            // console.log("COURSE actions 1 payload");
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/course/" + payload, headers)
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "COURSE actions 2.1 befor COURSE response %o",
                    //     response
                    // );
                    let items = response.data;
                    // console.log("COURSE actions 2.1.1 items %o", items);
                    // console.log("COURSE actions 2.1.2  context %o", context);
                    context.commit("SET_SELECTED_COURSE", items);
                    // console.log(
                    //     "COURSE actions 2.1.4 after commit SET_COURSE %o",
                    //     context
                    // );
                    // context.CLASSES(context, payload)
                    // console.log(
                    //     "COURSE actions 2.1.5 after commit CLASSES %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log("COURSE actions 2.2  error: %o", error);
                    context.commit("SET_COURSES", []);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
            // console.log("COURSE actions 3  exit");
        },
        CLASSES: async (context, payload) => {
            // debugger
            // console.log("CLASSES actions 1 payload");
            if (payload === undefined) {
                console.log("CLASSES actions 1.1 payload undefined");
                return;
            }
            // let out = { course_id: payload };
            // const str_param = JSON.stringify(out);
            // let params = "?where=" + str_param;
            // console.log("CLASSES actions 1.2 params %o", params);
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/classes/" + payload, headers)
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "CLASSES actions 2.1 befor CLASSES response %o",
                    //     response
                    // );
                    let items = response.data._items;
                    // console.log("CLASSES actions 2.1.1 items %o", items);
                    // console.log("CLASSES actions 2.1.2  context %o", context);
                    context.commit("SET_CLASSES", items);
                    // console.log(
                    //     "CLASSES actions 2.1.4 after commit SET_COURSE %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log("CLASSES actions 2.2  error: %o", error);
                    context.commit("SET_CLASSES", []);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
            // console.log("CLASSES actions 3  exit");
        },
        BLOCKS: async (context, payload) => {
            // debugger
            // console.log("BLOCKS actions 1 payload");
            if (payload === undefined) {
                console.log("BLOCKS actions 1.1 payload undefined");
                return;
            }
            // let out = { class_id: payload };
            // const str_param = JSON.stringify(out);
            // let params = "?where=" + str_param;
            // console.log("BLOCKS actions 1.2 params %o", params);
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/block/" + payload, headers)
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "BLOCKS actions 2.1 befor COURSE response %o",
                    //     response
                    // );
                    let items = response.data._items;
                    // console.log("BLOCKS actions 2.1.1 items %o", items);
                    // console.log("BLOCKS actions 2.1.2  context %o", context);
                    context.commit("SET_BLOCKS", items);
                    // console.log(
                    //     "BLOCKS actions 2.1.4 after commit SET_COURSE %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log("BLOCKS actions 2.2  error: %o", error);
                    context.commit("SET_BLOCKS", []);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
            // console.log("BLOCKS actions 3  exit");
        },
        GET_BLOCKS_FOR_CLASS: async (context, payload) => {
            // debugger
            // console.log("GET_BLOCKS_FOR_CLASS actions 1 payload");
            if (payload === undefined) {
                console.log(
                    "GET_BLOCKS_FOR_CLASS actions 1.1 payload undefined"
                );
                return;
            }
            // let out = { class_id: payload };
            // const str_param = JSON.stringify(out);
            // let params = "?where=" + str_param;
            // console.log("GET_BLOCKS_FOR_CLASS actions 1.2 params %o", params);
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/block/class/" + payload, headers)
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "BLOCKS actions 2.1 befor COURSE response %o",
                    //     response
                    // );
                    let items = response.data._items;
                    // console.log("BLOCKS actions 2.1.1 items %o", items);
                    // console.log("BLOCKS actions 2.1.2  context %o", context);
                    context.commit("SET_BLOCKS", items);
                    // console.log(
                    //     "BLOCKS actions 2.1.4 after commit SET_COURSE %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "GET_BLOCKS_FOR_CLASS actions 2.2  error: %o",
                        error
                    );
                    context.commit("SET_BLOCKS", []);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
            // console.log("BLOCKS actions 3  exit");
        },
        GET_CLASS: async (context, payload) => {
            // debugger
            console.log("GET_CLASS actions 1 payload %o", payload);
            if (payload === undefined) {
                console.log("GET_CLASS actions 1.1 payload undefined");
                return;
            }
            // let out = { class_id: payload };
            // const str_param = JSON.stringify(out);
            // let params = "?where=" + str_param;
            // console.log("GET_CLASS actions 1.2 params %o", params);
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/class/" + payload, headers)
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "GET_CLASS actions 2.1 befor COURSE response %o",
                    //     response
                    // );
                    let items = response.data.class;
                    // console.log("GET_CLASS actions 2.1.1 items %o", items);
                    // console.log("GET_CLASS actions 2.1.2  context %o", context);
                    context.commit("SET_SELECTED_CLASS", items);
                    // console.log(
                    //     "GET_CLASS actions 2.1.4 after commit SET_COURSE %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log("GET_CLASS actions 2.2  error: %o", error);
                    context.commit("SET_SELECTED_CLASS", []);
                });
            // console.log("BLOCKS actions 3  exit");
        },
        GET_BLOCK: async (context, payload) => {
            // debugger
            console.log("GET_BLOCK actions 1 payload %o", payload);
            if (payload === undefined) {
                console.log("GET_BLOCK actions 1.1 payload undefined");
                return;
            }
            // let out = { class_id: payload };
            // const str_param = JSON.stringify(out);
            // let params = "?where=" + str_param;
            // console.log("GET_CLASS actions 1.2 params %o", params);
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            };
            await Axios.get(admin_back + "/block/" + payload, headers)
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "GET_CLASS actions 2.1 befor COURSE response %o",
                    //     response
                    // );
                    let items = response.data.class;
                    // console.log("GET_CLASS actions 2.1.1 items %o", items);
                    // console.log("GET_CLASS actions 2.1.2  context %o", context);
                    context.commit("SET_SELECTED_BLOCK", items);
                    // console.log(
                    //     "GET_CLASS actions 2.1.4 after commit SET_COURSE %o",
                    //     context
                    // );
                })
                .catch((error) => {
                    // debugger
                    console.log("GET_BLOCK actions 2.2  error: %o", error);
                    context.commit("SET_SELECTED_BLOCK", []);
                });
            // console.log("BLOCKS actions 3  exit");
        },
        UPDATE_BLOCK_ATTRIBUTES: async (context, payload) => {
            // UPDATE_USER_COURSES

            let data = {
                id: payload._id,
                etag: payload._etag,
                title: payload.title,
                text: payload.text,
                block_type: payload.block_type,
            };

            if ("variants" in payload) {
                data["variants"] = JSON.parse(JSON.stringify(payload.variants));
            }
            // console.log("UPDATE_USER_COURSES actions 1 data=%o ", data);
            console.log(
                `%c${file}  UPDATE_BLOCK_ATTRIBUTES 1  payload=%o data=%o`,
                payload,
                data
            );
            await Axios.put(admin_back + "/block", data, {
                // data: data
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + context.state.token,
                },
            })
                .then((response) => {
                    console.log(
                        `%c${file}  UPDATE_BLOCK_ATTRIBUTES 2.1 befor response.data.length=%o data=%o`,
                        response.data.length,
                        response.data
                    );
                    let block = {
                        _id: data.id,
                        _etag: response.data.block["_etag"],
                        title: data["title"],
                        text: data["text"],
                        block_type: data["block_type"],
                    };
                    if ("variants" in payload) {
                        block["variants"] = JSON.parse(
                            JSON.stringify(payload.variants)
                        );
                    }
                    console.log(
                        "UPDATE_BLOCK_ATTRIBUTES actions 3 block=%o ",
                        block
                    );
                    context.commit("SET_BLOCK_ATTRIBUTES", block);
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "UPDATE_BLOCK_ATTRIBUTES actions 2.2 befor SET_CERT_ERROR error %o",
                        error
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        UPLOAD: async (context, payload) => {
            console.log("UPLOAD actions 1 payload %o", payload);
            // debugger;
            // let payment_url_blob = new Blob([payload.payment_url], {
            //     type: "text/plain"
            // });
            let formData = new FormData();
            // console.log("UPLOAD actions 2 Form Data %o", formData);
            formData.append("trial_time", payload.trial_time);
            formData.append("payment_url", payload.payment_url);
            // formData.append("files", payment_url_blob, "payment_url");
            formData.append(
                "files",
                payload.course_file,
                payload.course_file.name
            );
            for (var i = 0; i < payload.classes.length; i++) {
                formData.append(
                    "files",
                    payload.classes[i],
                    payload.classes[i].name
                );
            }

            // console.log("UPLOAD actions 3 payload");
            if (payload === undefined) {
                console.log("UPLOAD actions 3.1 payload undefined");
                return;
            }
            // console.log("UPLOAD actions 4");
            context.commit("CLEAR_ERROR");
            context.commit("CLEAR_RESPONSE");
            await Axios.post(admin_back + "/addcourse", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + context.state.token,
                },
            })
                .then((response) => {
                    console.log("UPLOAD actions GOOD response %o", response);
                    // debugger
                    context.commit("SET_RESPONSE", {
                        status: response.status,
                        statusText: response.statusText,
                        data: response.data,
                    });
                })
                .catch((error) => {
                    // debugger
                    console.log("UPLOAD actions 6 ERROR response %o", error);
                    let error_info = {
                        status: error.response.status,
                        statusText: error.response.statusText,
                        data: error.response.data,
                    };
                    context.commit("SET_ERROR", error_info);
                    context.commit("SET_RESPONSE", error_info);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
            // console.log("UPLOAD actions 7  exit");
        },
        LOGOUT: async (context) => {
            // debugger
            // console.log("LOGOUT actions 1 payload=%o", payload);
            localStorage.setItem("token", "");
            context.commit("SET_TOKEN", "");
        },

        changeCourse(context, args) {
            let course = context.state.courses.filter(
                (el) => el.id === args.course_id
            ); // Get current course
            if (course.length > 1) {
                let msg = `store function: changeCourse. duplicate course_id in classes. course_id: ${args.course_id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }
            course = course[0];
            course.position = -1;
            course.head = true;
            context.commit("course", course);
        },
        getClasses(context) {
            let course = context.state.course; // Get current course
            if (Object.keys(course).length === 0) {
                let msg = `store function: changeCourse. not found course`;
                logger.fatalFunction(msg);
                return [];
            }
            let classes = mock_classes.get_classes(course.id);
            course.position = -1;
            course.head = true;
            classes.unshift(course);
            context.commit("classes", classes);
            return context.state.classes;
        },
        getCourses(context) {
            let courses_request = mock_courses.get_courses();
            let courses = context.state.courses;
            if (courses.length > 0) {
                return courses;
            }
            // TODO: change to request to backend
            context.commit("courses", courses_request);
            if (context.state.courses.length === 0) {
                logger.warning("empty slice with courses was got");
            }
            return context.state.courses;
        },
        UPDATE_USER_STATE: async (context, payload) => {
            // debugger
            // console.log("UPDATE_USER_STATE actions 1 payload=%o", payload);
            let user = context.state.users.filter(
                (el) => el._id === payload.item._id
            );
            // console.log("UPDATE_USER_STATE actions 1 data=%o user: %o", user);
            let data = {
                active: payload.item.active,
                etag: user[0]._etag,
            };
            // console.log(
            //     "UPDATE_USER_STATE actions 1 data=%o user: %o",
            //     data,
            //     user
            // );
            // debugger;
            await Axios.patch(
                admin_back + "/userstate/" + payload.item._id,
                data,
                {
                    // data: data
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Bearer " + context.state.token,
                    },
                }
            )
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "UPDATE_USER_STATE actions 2.1 befor GET_TOKEN.length=",
                    //     response.data.length
                    // );
                    context.commit("SET_USER_STATE", {
                        index: payload.index,
                        state: data.active,
                        etag: response.data.user["_etag"],
                        id: response.data.user["_id"],
                    });
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        "UPDATE_USER_STATE actions 2.2 befor SET_CERT_ERROR"
                    );
                    context.commit("SET_CERT_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        UPDATE_COURSE_STATE: async (context, payload) => {
            // debugger
            console.log(
                `%c${file}UPDATE_COURSE_STATE actions 1 payload=%o  context.state.courses %o`,
                compColor,
                payload,
                context.state.courses
            );
            let index = context.state.courses.findIndex(
                (el) => el._id === payload.course_id
            );
            if (index < 0) {
                let msg = `UPDATE_COURSE_STATE: not found course with course_id: ${payload.course_id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }

            let data = {
                available: payload.active,
                etag: context.state.courses[index]._etag,
                course_id: payload.course_id,
            };
            console.log(
                `%c${file}UPDATE_COURSE_STATE actions 2 data=%o`,
                compColor,
                data
            );
            // debugger;
            await Axios.patch(
                admin_back + "/coursestate/" + payload.course_id,
                data,
                {
                    // data: data
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Bearer " + context.state.token,
                    },
                }
            )
                .then((response) => {
                    //  debugger
                    console.log(
                        `%c${file} UPDATE_COURSE_STATE actions 2.1 befor response.data.length= %o  response.data %o`,
                        compColor,
                        response.data.length,
                        response.data
                    );
                    context.commit("SET_COURSE_STATE", {
                        available: data.available,
                        etag: response.data.course["_etag"],
                        id: response.data.course["_id"],
                    });
                })
                .catch((error) => {
                    // debugger
                    console.log(
                        `%c${file} UPDATE_COURSE_STATE actions 2.2 befor SET_CERT_ERROR  error %o`,
                        compColor,
                        error
                    );
                    context.commit("SET_CERT_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        MARK_COURSE_FOR_DELETE: async (context, payload) => {
            // debugger
            console.log("MARK_COURSE_FOR_DELETE actions 1 payload=%o", payload);
            let index = context.state.courses.findIndex(
                (el) => el._id === payload._id
            );
            if (index < 0) {
                let msg = `MARK_COURSE_FOR_DELETE: not found course with course_id: ${payload.course_id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }

            let data = {
                available: payload.active,
                etag: payload._etag,
                course_id: payload._id,
            };
            console.log("MARK_COURSE_FOR_DELETE actions 2 data=%o", data);
            // debugger;
            await Axios.patch(
                admin_back + "/markcoursedeleted/" + payload._id,
                data,
                {
                    // data: data
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Bearer " + context.state.token,
                    },
                }
            )
                .then((response) => {
                    //  debugger
                    // console.log(
                    //     "UPDATE_COURSE_STATE actions 2.1 befor response.data.length=",
                    //     response.data.length
                    // );
                    context.commit("DELETE_COURSE", {
                        id: response.data["_id"],
                    });
                })
                .catch((error) => {
                    // debugger;
                    console.log(
                        "MARK_COURSE_FOR_DELETE actions 2.2 befor SET_ERROR error: %o"
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
        UPDATE_COURSE_TRIAL_TIME: async (context, payload) => {
            // debugger
            console.log(
                "UPDATE_COURSE_TRIAL_TIME actions 1 payload=%o",
                payload
            );
            let index = context.state.courses.findIndex(
                (el) => el._id === payload._id
            );
            if (index < 0) {
                let msg = `UPDATE_COURSE_TRIAL_TIME: not found course with course_id: ${payload._id}`;
                logger.fatalFunction(msg);
                throw new Error();
            }

            let data = {
                trial_time: payload.trial_time,
                etag: payload._etag,
                course_id: payload._id,
            };
            console.log("UPDATE_COURSE_TRIAL_TIME actions 2 data=%o", data);
            // debugger;
            await Axios.patch(
                admin_back + "/coursetrialtime/" + payload._id,
                data,
                {
                    // data: data
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Bearer " + context.state.token,
                    },
                }
            )
                .then((response) => {
                    //  debugger
                    console.log(
                        "SET_COURSE_TRIAL_TIME actions 2.1 befor response.data.length=%o data: %o",
                        response.data.length,
                        response.data
                    );
                    context.commit("SET_COURSE_TRIAL_TIME", {
                        id: response.data["course"]["_id"],
                        _etag: response.data["course"]["_etag"],
                        trial_time: response.data["trial_time"],
                    });
                })
                .catch((error) => {
                    // debugger;
                    console.log(
                        "UPDATE_COURSE_TRIAL_TIME actions 2.2 befor SET_ERROR error: %o",
                        error
                    );
                    context.commit("SET_ERROR", error);
                    if (error.response.status == 401) {
                        context.commit("LOGOUT", "");
                    }
                });
        },
    },
});
