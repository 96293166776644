<template>
    <v-app id="inspire">
        <v-content>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="elevation-12">
                            <v-toolbar color="#FBC02D" dark flat>
                                <v-toolbar-title>Login</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        label="Login"
                                        name="login"
                                        prepend-icon="mdi-account"
                                        type="text"
                                        v-model="login"
                                    />

                                    <v-text-field
                                        id="password"
                                        label="Password"
                                        name="password"
                                        prepend-icon="mdi-lock"
                                        type="password"
                                        v-model="password"
                                    />
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <router-link
                                    style="margin-right: 4px"
                                    class="custom-nav-button"
                                    to="/"
                                >
                                    <v-btn
                                        fab
                                        color="#ffc72c"
                                        @click="do_login"
                                    >
                                        <v-icon color="black">mdi-login</v-icon>
                                    </v-btn>
                                </router-link>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Login",
    props: {
        source: String
    },
    data: () => ({
        login: "",
        password: ""
    }),

    computed: {
        ...mapGetters(["isLoggedIn", "getToken"])
    },

    methods: {
        ...mapActions(["LOGIN", "LOGOUT"]),

        async do_login(e) {
            // if (this.$v.$invalid) {
            //     this.$v.$touch();
            //     return;
            // }
            e.preventDefault();
            let body = {
                password: this.password,
                username: this.login
            };
            // console.log("Login:", body);
            // console.log(
            //     "isLoggedIn %o token %o ------------------",
            //     this.isLoggedIn,
            //     this.getToken
            // );
            await this.LOGIN(body);
            // console.log(
            //     "isLoggedIn %o token %o ==================",
            //     this.isLoggedIn,
            //     this.getToken
            // );
            if (this.isLoggedIn) {
                this.$router.push("/");
            } else {
                // this.$router.push("/login");
            }
        }
    }
};
</script>
