<template>
    <v-btn
        fab
        fixed
        left
        bottom
        color="white"
        class="custom-debug custom-first-lbbutton"
    >
        <v-progress-circular
            :rotate="360"
            :size="57"
            :width="3"
            :value="100 * answered / all_answers"
            color="#ffc72c"
            class="custom-debug"
            style="padding-left: 4px"
        >
            <v-card-text class="subtitle-1">
                <b>{{ Math.round(100 * answered / all_answers) + "%" }}</b>
            </v-card-text>
        </v-progress-circular>
    </v-btn>
</template>

<script>
    export default {
        props: ['answered', 'all_answers'],
        name: "ProgressBarBottom"
    }
</script>

<style scoped>

</style>
