<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="410">
            <v-card>
                <v-card-title class="headline yellow darken-2">
                    Agree to delete a course?
                </v-card-title>
                <v-card-title class="headline">
                    Attention course "{{ course ? course.title : "" }}" will be
                    deleted.
                </v-card-title>
                <v-card-text>
                    {{ course ? course.description : "" }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">
                        Disagree
                    </v-btn>
                    <v-btn color="red darken-1" text @click="course_delete">
                        Agree
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
const file = "components/iter2/CourseDelete.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff00B0";
import utils from "../../plugins/iter2/utils";

import { mapGetters, mapActions } from "vuex";
export default {
    name: "CourseDelete",
    components: {},
    props: ["course", "openDialog"],
    data: () => ({
        dialog: false
    }),
    created: function() {
        if (utils.is_mobile()) {
            this.title = "Mobile version in development";
            this.loading = true;
            return;
        }
        this.dialog = this.openDialog;
        console.log(
            `%c${file} created this.course %o openDialog  %o`,
            compColor,
            this.course,
            this.openDialog
        );
    },
    computed: {
        ...mapGetters({
            all_courses: "COURSES"
        })
    },
    methods: {
        ...mapActions(["COURSES", "MARK_COURSE_FOR_DELETE"]),
        async course_delete() {
            console.log(
                `%c${file} course_delete  this.course %o`,
                compColorI,
                this.course
            );
            console.log("delete_course befor course: %o", this.course);
            await this.MARK_COURSE_FOR_DELETE(this.course);
            console.log("delete_course after course: %o", this.course);
            this.dialog = false;
            await this.$router.push(`/v2/courses`);
        }
        // async delete_course(course) {
        //     // this.UPDATE_COURSE_STATE({ course_id: course._id, active: state });
        //     console.log("delete_course befor course: %o", course);
        //     await this.MARK_COURSE_FOR_DELETE(course);
        //     console.log("delete_course after course: %o", course);
        //     return false;
        // }
    }
};
</script>

<style>
@import "../../assets/iter2/css/app.css";
@import "../../assets/iter2/css/new/border.css";
@import "../../assets/iter2/css/new/courses.css";
@import "../../assets/iter2/css/new/colors.css";
</style>
