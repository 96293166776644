<template>
  <v-container>

    <v-card
      class="d-flex justify-center custom-debug"
      :elevation="debug_elevation"
    >
      <v-card
        class="custom-block-inner custom-debug"
        :elevation="elevation"
      >
        <v-card-title
            class="justify-start display-1 custom-debug"
        >
            {{title}}
        </v-card-title>
        <v-card-text
            v-html="question"
            class="justify-start font-weight-regular title custom-debug black--text"
        />
          <v-card-title
              class="justify-start display-1 custom-debug"
          >
              Ваш ответ:
          </v-card-title>
        <v-card-actions
          class="subtitle-1 custom-debug"
          :hidden="checked"
        >

            <v-container
                class="custom-debug custom-checkbox-answer"
                v-if="accepted"
            >
                <v-container fluid>
                    <v-checkbox
                        class="custom-checkbox-answer"
                        color="orange"
                        v-for="(item, i) in answer"
                        :key="item.id"
                        v-model="checkboxes[i]"
                        :label="item.variant"
                    />
                </v-container>
            </v-container>
            <v-btn color="#ffc72c" fab small dark @click="send">
                <v-icon>mdi-send</v-icon>
            </v-btn>
        </v-card-actions>
        <v-card-actions
          class="subtitle-1 custom-debug black--text"
          :hidden="!checked"
        >
            <v-container
                v-if="accepted"
            >
                <v-container
                    class="custom-block-truth custom-checkbox-answer"
                    fluid
                    v-for="item in answer"
                    :key="item.id"
                >
                    <v-checkbox
                        class="custom-debug custom-checkbox-answer"
                        :input-value="item.truth"
                        :label="item.variant"
                        disabled
                    />
                </v-container>
            </v-container>
            <v-container
                v-else
            >
                <v-container
                    class="custom-block-false custom-checkbox-answer"
                    fluid
                    v-for="item in answer"
                    :key="item.id"
                >
                    <v-checkbox
                        class="custom-debug custom-checkbox-answer"
                        :input-value="item.checked"
                        :label="item.variant"
                        disabled
                    />
                </v-container>
            </v-container>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-container>

</template>

<script>
    export default {
        name: "ClassBlockCheckboxQuestion",
        props: ['title', 'question', 'elevation', 'debug_elevation', 'checked', 'answer'],
        computed: {
            accepted() {
                let accepted = true;
                for (let i = 0; i < this.answer.length; i++) {
                    accepted &= this.answer[i].truth === this.answer[i].checked;
                }
                return accepted;
            }
        },
        data: () => ({
            checkboxes: [
                '',
                '',
                '',
                '',
                '',
                '',
            ]
        }),
        methods: {
            send: function() {
                console.log(123);
            }
        }
    }
</script>

<style scoped>
    @import '../../../assets/css/class_block.css';
</style>
