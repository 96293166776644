<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="500px"
            persistent
            style="background-color: white"
        >
            <v-card style="padding: 50px">
                <div style="display: flex; cursor: pointer; flex-direction: row; justify-content: flex-end; padding-bottom: 20px">
                    <v-icon v-on:click="close">
                        mdi-close
                    </v-icon>
                </div>
                <div style="display: flex; justify-content: center;">
                    <div>
                        Contact us: <u style="-webkit-user-select: all; -moz-user-select: all; -ms-user-select: all; user-select: all;">support@flessibilita.pro</u>
                    </div>
                    <div>
                        Development: <a href="https://urvanov.com"><u>urvanov.com</u></a>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "Info",
        props: ['dialog'],
        methods: {
            close() {
                this.$emit('close_info');
            },
        }
    }
</script>

<style scoped>

</style>
