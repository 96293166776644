<template>
    <v-app class="outer background-color">
        <v-snackbar v-model="show_message">
            {{ message }}
        </v-snackbar>
        <Loading class="text-center" v-if="loading" :title="title" />
        <Navigations v-if="!loading" />
        <div class="break-line-cards" v-show="!loading">
            <div class="custom-app-containers offset-align-cards display-1">
                <b>{{ title }}</b>
            </div>

            <router-link to="/v2/addcourse">
            <v-btn
                fab
                fixed
                right
                bottom
                color="#ffc72c"
                class="custom-first-rtbutton"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </router-link>
            <div v-for="course in courses" :key="course.id">
                <Card :course="course" />
            </div>
        </div>
    </v-app>
</template>

<script>
const file = "views/iter2/Courses.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff00B0";
import Card from "../../components/iter2/courses/Card";
import Loading from "../../components/iter2/Loading";
import Navigations from "../../components/iter2/Navigations";
import utils from "../../plugins/iter2/utils";

import { mapGetters, mapActions } from "vuex";
export default {
    name: "Courses",
    components: { Loading, Card, Navigations },
    data: () => ({
        title: "All courses",
        loading: true,
        message: "",
        show_message: false,
        courses: [],
        settings: null
    }),
    created: async function() {
        // TODO: add try catch
        if (utils.is_mobile()) {
            this.title = "Mobile version in development";
            this.loading = true;
            return;
        }
        this.loading = true;

        console.log(
            `%c${file} created this.courses %o`,
            compColor,
            this.courses
        );
        await this.COURSES();
        if (this.ERROR) {
            `%c${file} created this.ERROR %o   this.all_courses %o`,
                compColorI,
                this.ERROR,
                this.all_courses;
            this.loading = false;
            this.$router.push({ path: `/login` });
        }

        this.courses = this.all_courses;
         this.settings = this.get_settings;
        this.loading = false;
        console.log(
            `%c${file} created this.courses %o   this.all_courses %o`,
            compColorI,
            this.courses,
            this.all_courses
        );
    },
    computed: {
        ...mapGetters({
            all_courses: "COURSES",
            ERROR: "ERROR",
             get_settings: "settings",
        })
    },
    methods: {
        ...mapActions(["COURSES"]),
    }
};
</script>

<style>
@import "../../assets/iter2/css/app.css";
@import "../../assets/iter2/css/new/border.css";
@import "../../assets/iter2/css/new/courses.css";
@import "../../assets/iter2/css/new/colors.css";
</style>
