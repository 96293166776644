<template>
    <v-container>
        <v-hover v-slot:default="{ hover }">
            <v-card
                width="344"
                class="custom-debug"
                :elevation="
                    course_available(course)
                        ? hover
                            ? settings.hover_elevation
                            : settings.elevation
                        : settings.elevation
                "
            >
                <a
                    @click="() => goToClasses(course)"
                    class="custom-debug custom-nav-button"
                    :style="
                        course_available(course) ? `cursor: pointer` : `cursor: auto`
                    "
                >
                    <v-card-text>
                        <v-toolbar-title
                            class="custom-debug subtitle-1"
                            style="color: black"
                        >
                            {{ course === undefined || course == null ? "": course.title }}
                        </v-toolbar-title>
                    </v-card-text>
                    <div :style="gray_scale(course)">
                        <v-img
                            :src="course === undefined  || course == null ? '' :course.image_url"
                            style="height: 200px;"
                            class="custom-debug"
                        />
                    </div>
                    <v-card-text
                        class="custom-debug d-flex text-lg-justify grey--text"
                    >
                        {{ cut(course === undefined  || course == null ? '' :course.description, max_length_description) }}
                    </v-card-text>
                </a>
            </v-card>
        </v-hover>
        <Loading class="text-center" v-show="loading" />
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "../Loading";
export default {
    name: "CourseCard",
    components: { Loading },
    
    created() {
        this.settings = this.$store.state.settings;
        // console.log(
        //     "components/course/CourseCardSelected.vue this %o ",
        //     this
        // );
    },
    // beforeCreate() {
    //     console.log("components/course/CourseCardSelected.vue beforeCreate 1");
    // },
    // beforeMount() {
    //     console.log("components/course/CourseCardSelected.vue beforeMount 1");
    // },
    // mounted() {
    //     console.log("components/course/CourseCardSelected.vue mounted 1");
    // },
    // beforeUpdate() {
    //     console.log("components/course/CourseCardSelected.vue beforeUpdate 1");
    // },
    // updated() {
    //     console.log("components/course/CourseCardSelected.vue updated 1");
    // },
    computed: {
        ...mapGetters({
            course: "SELECTED_COURSE"
        }),},
    methods: {
        course_available: course => {
                //             console.log(
                //     "components/course/CourseCardSelected.vue  course_available course %o ",
                //     course
                // );

            if (course === undefined || course == null) {
                    //         console.log(
                    // "components/course/CourseCardSelected.vue  course_available course === undefined %o",
                    // course
                // );
                return false;
            }

            return course.available;
        },
        gray_scale: course => {
            if (course === undefined || course == null) {
                // console.log(
                //     "components/course/CourseCardSelected.vue  goToClasses this %o",
                //     this
                // );
                return '';
            }
            return !course.available ? `filter: grayscale(100%)` : ``;
        },
        goToClasses(course) {
            // console.log(
            //     "components/course/CourseCardSelected.vue  goToClasses props.course %o    course %o",
            //     this.$props.course,
            //     course
            // );
            this.$router.push({ path: `/course/${course._id}` });
        },
        cut(text) {
            if (!!text && text.length > this.max_length_description) {
                return text.slice(0, this.max_length_description) + "...";
            }
            return text;
        },
        class_status(cl) {
            return (100 * cl.solved_classes) / cl.amount_classes;
        }
    },
    data: () => ({
        max_length_description: 230,
        loading: false,
        settings: null,
        hover: false
    })
};
</script>

<style scoped>
@import "../../assets/css/navigation_buttons.css";
</style>
