<template>
    <v-app
        id="inspire"
        style="background-color: #ffffff"
    >
        <Loading
            :title="message"
            class="text-center"
        />
    </v-app>
</template>

<script>
    import Loading from "../components/Loading";
    import time from '../plugins/time';
    export default {
        name: 'Error',
        components: {Loading},
        async created() {
            await time.sleep(this.timeout);
            await this.$router.push('/');
        },
        data: () => ({
            timeout: 5000, // 5 seconds before route to /
            message: 'Мы проверяем Ваши работы. Продолжить обучение Вы сможете позже.' +
                ' Через несколько секунд страница будет перезагружена'
        })
    };
</script>

<style>
</style>

