<template>
    <v-container :style="{'margin-top': margin_}">
        <v-progress-circular
            :size="size_"
            :width="width_"
            :color="settings.general_color"
            style="padding: 50px;"
            indeterminate
        >
            <span style="color: black">
                <b>{{title_}}</b>
            </span>
        </v-progress-circular>
    </v-container>
</template>

<script>
    export default {
        name: "Loading",
        props: ['title', 'size', 'margin', 'width'],
        created() {
            if (typeof this.margin !== 'undefined') {
                this.margin_ = this.margin
            }
            if (this.title !== undefined) {
                this.title_ = this.title;
            }
            if (this.size !== undefined) {
                this.size_ = this.size;
            }
            if (this.width !== undefined) {
                this.width_ = this.width;
            }
            this.settings = this.$store.getters.settings;
        },
        data: () => ({
            settings: null,
            width_: 15,
            margin_: '150px',

            size_: 500,
            title_: 'Loading...',
        })
    }
</script>

<style scoped>

</style>
