<template>
    <v-app id="inspire">
        <v-app-bar app clipped-left color="amber">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <span class="title ml-3 mr-5"
                >Fless &nbsp; <span class="font-weight-light">Admin</span></span
            >
            <v-spacer></v-spacer>
            <router-link to="/v2/courses">
                <v-btn class="ma-2" outlined color="indigo">
                    New version
                </v-btn>
            </router-link>
            <v-spacer></v-spacer>
        </v-app-bar>
        <Courses />
    </v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import Courses from "../views/CoursesView";
// import Classes from "../views/Classes";
export default {
    name: "Admin",
    components: {
        Courses
    },
    methods: {
        ...mapMutations(["LOGOUT"]),
        action(e) {
            console.log("action:", e);
            if (e == "Logout") {
                this.logout(e);
            } else if (e == "Users") {
                this.users(e);
            } else if (e == "Courses") {
                this.courses(e);
            }
        },
        logout(e) {
            console.log("logout:", e);
            this.LOGOUT();
            this.$router.push("/login");
        },
        users(e) {
            console.log("users:", e);
            this.isCourses = false;
            this.isUsers = true;
            this.isClasses = false;
        },
        courses(e) {
            console.log("courses:", e);
            this.isCourses = true;
            this.isUsers = false;
            this.isClasses = false;
        }
    },
    computed: {
        ...mapGetters(["isLoggedIn"])
    },

    data: () => ({
        drawer: null,
        isCourses: false,
        isUsers: false,
        items: [
            { icon: "link", text: "Courses", action: "Courses" },
            { icon: "people", text: "Users", action: "Users" },
            { divider: true },
            { icon: "logout", text: "Logout", action: "Logout" },
            { divider: true }
        ]
    })
};
</script>

<style scoped>
@import "../assets/css/navigation_buttons.css";
@import "../assets/css/class_title.css";
</style>
