<template>
    <v-container>
        <router-link to="/">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-first-rtbutton"
            >
                <v-icon>mdi-home</v-icon>
            </v-btn>
        </router-link>
        <router-link to="/addcourse">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-second-rtbutton"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </router-link>
      <router-link to="/editcourse">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-third-rtbutton"
                disabled
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </router-link>
        <router-link to="/delcourse">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-fourth-rtbutton"
                disabled
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </router-link>
        <router-link to="/edit">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-debug custom-fifth-rtbutton"
                disabled
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </router-link>
        <router-link to="/users">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-debug custom-fifth-rtbutton"
            >
                <v-icon>mdi-account-multiple</v-icon>
            </v-btn>
        </router-link>
    </v-container>
</template>

<script>
export default {
    name: "NavigationCoursesButtons",
    props: ["navigation"],
    methods: {
        pageHeight() {
            return document.body.scrollHeight;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        toDown() {
            this.$vuetify.goTo(this.pageHeight());
        },
        toMiddle() {
            this.$vuetify.goTo((1.2 * this.pageHeight()) / 3);
        }
    }
};
</script>

<style scoped>
@import "../../assets/css/navigation_buttons.css";
@import "../../assets/css/class_title.css";
</style>
