<template>
  <v-container>

    <v-card
      class="d-flex justify-center custom-debug"
      :elevation="debug_elevation"
    >
      <v-card
        class="custom-block-inner custom-debug"
        :elevation="elevation"
      >
        <v-card-title
          class="justify-start display-1 custom-debug"
        >
          {{title}}
        </v-card-title>
        <v-card-text
          v-html="text"
          class="justify-start font-weight-regular title  custom-debug black--text"
        />
      </v-card>
    </v-card>
  </v-container>

</template>

<script>
  export default {
    name: "ClassBlockText",
    props: ['title', 'text', 'elevation', 'debug_elevation'],
  }
</script>

<style scoped>
  @import '../../../assets/css/class_block.css';
</style>
