export default {
    warningComponent(component, e) {
        // TODO: добавить отправку сообщения в сервис ошибок
        // TODO: перенести во vuex
        console.warn(component.$options.name, e);
    },
    fatalComponent(component, e) {
        console.error(component.$options.name, e);
        // TODO: добавить отправку сообщения в сервис ошибок
        // TODO: перенести во vuex
    },
    warningFunction(f, e) {
        // TODO: добавить отправку сообщения в сервис ошибок
        // TODO: перенести во vuex
        console.warn('function: '+f, e);
    },
    fatalFunction(f, e) {
        console.error('function: '+f, e);
        // TODO: добавить отправку сообщения в сервис ошибок
        // TODO: перенести во vuex
    },
}
