<template>
    <v-container>
        <v-card
            style="padding: 20px"
            class="d-flex justify-center custom-debug"
        >
            <v-text-field
                v-model="first_name"
                :counter="40"
                :rules="nameRules"
                label="First Name"
                required
            ></v-text-field>

            <v-text-field
                v-model="last_name"
                :counter="40"
                :rules="nameRules"
                label="Last Name"
                required
            ></v-text-field>

            <v-text-field
                v-model="name"
                :counter="20"
                :rules="nameRules"
                label="Phone Number"
                required
            ></v-text-field>
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                disabled
            ></v-text-field>
            <v-container
                class="d-flex justify-center"
            >
                <v-btn color="#ffc72c" fab small dark @click="send">
                    <v-icon>mdi-send</v-icon>
                </v-btn>
            </v-container>
        </v-card>
        <NavigationAccountButtons
            class="custom-debug custom-app-containers"
        />
    </v-container>
</template>

<script>
    import NavigationAccountButtons from "../components/navs/NavigationAccountButtons";
    export default {
        name: 'Account',
        components: {NavigationAccountButtons},
        data: () => ( {
            started: false,
            blocks: [],
            elevation: this.$store.state.elevation,
            debug_elevation: this.$store.state.debug_elevation,
        } ),
    };
</script>

<style>
    /*@import "../assets/css/debug.css";*/
    @import "../assets/css/app.css";
</style>

