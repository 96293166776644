<template>
    <v-card class="mx-auto" width="600">
        <v-list three-line>
            <template v-for="(item, index) in blocks">
                <v-list-item :key="item._id" @click="onClickList(item, index)">
                    <v-list-item-content>
                        <v-list-item-title
                            v-html="item.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                            v-html="item.text"
                        ></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>

    </v-card>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
// import { mapGetters, mapActions } from "vuex";
// import { mapGetters } from "vuex";
export default {
    name: "Blocks",
    props: ["currentclass"],
    data: () => ({
        sel: null,
        tree: [],
        selection: {}
    }),
    created: async function() {
        // this.courses = await this.$store.dispatch("getUsers");
        // console.log("components/class/Blocks.vue component created");
        if (this.$props.currentclass == null) {
            // console.log(
            //     "components/class/Blocks.vue created props: currentclass == null"
            // );
            return;
        }
        // let prp = this.$props;
        // console.log("components/class/Blocks.vue created props: %o", prp);
        let class_id = this.$props.currentclass._id;
        // console.log(
        //     "components/class/Blocks.vue created class_id: %o",
        //     class_id
        // );
        await this.initialize(class_id);
        // console.log(
        //     "components/class/Blocks.vue created 3  course: %o",
        //     this.course
        // );
        // console.log(
        //     "components/class/Blocks.vue created 3  classes: %o",
        //     this.classes
        // );
    },
    methods: {
        selItem() {
            // let id = 0;
            // console.log(
            //     "components/class/Blocks.vue select item %o sel: %o",
            //     id,
            //     this.sel
            // );
            // this.selected_class(id);
        },
        onClick(item) {
            // console.log("components/class/Blocks.vue select item %o ", item);
            this.selection = item;
        },
        onClickList(item) {
            // console.log(
            //     "components/class/Blocks.vue onClickList select item %o index %o",
            //     item,
            //     index
            // );
            this.selection = item;
            this.SET_SELECTED_BLOCK(item);
        },
        // ...mapMutations({
        //     selected_class: "SET_SELECTED_CLASS"
        // }),
        ...mapActions(["BLOCKS"]),
        ...mapMutations(["SET_SELECTED_BLOCK"]),
        async initialize(id) {
            // console.log("components/class/Blocks.vue initialize 1 id: %o", id);
            this.SET_SELECTED_BLOCK(null);

            await this.BLOCKS(id);
            // console.log("components/class/Blocks.vue initialize 2 id: %o", id);
        }
    },
    computed: {
        ...mapGetters({
            // course: "COURSE",
            SELECTED_BLOCK: "SELECTED_BLOCK",
            blocks: "BLOCKS",
            getSelectedClass: "getSelectedClass"
        }),
        filled_tree() {
            // console.log(
            //     "components/class/Blocks.vue getSelectedClass: %o ",
            //     this.getSelectedClass
            // );
            let tree = this.blocks;
            let tree_out = [];

            // console.log("components/class/Blocks.vue tree: %o", tree);
            tree.forEach(function(item) {
                // console.log(
                //     "components/class/Blocks.vue i: %o   item: %o ",
                //     i,
                //     item
                // );
                if (Object.keys(item.next_blocks).length > 1) {
                    // console.log(
                    //     "components/class/Blocks.vue keys > 1 keys: %o",
                    //     Object.keys(item.next_blocks)
                    // );
                    tree_out.push(item);
                    let name_key = Object.keys(item.next_blocks)[1];
                    // console.log(
                    //     "components/class/Blocks.vue i %o name_key: %o ; %o",
                    //     i,
                    //     name_key,
                    //     item.next_blocks[name_key]
                    // );
                    if (name_key != "unary") {
                        let children = {
                            name: "Вопрос",
                            title: "Comments ",
                            children: []
                        };
                        tree_out.push(children);
                    }
                } else {
                    // console.log(
                    //     "components/class/Blocks.vue keys <= 1 tree_out.length: %o, tree_out: %o",
                    //     tree_out.length,
                    //     tree_out
                    // );
                    tree_out[tree_out.length - 1]["children"].push(item);
                }
            });
            return tree_out;
        },
        formTitle() {
            return this.editedIndex === -1 ? "New User" : "Edit User";
        }
    },
    watch: {
        getSelectedClass: async function(newClass) {
            // console.log(
            //     "$$$$$$$$$$$$$$$ components/class/Blocks.vue watch getSelectedClass: %o  old %o   new %o",
            //     this.getSelectedClass,
            //     oldClass,
            //     newClass
            // );
            // console.log(
            //     "$$$$$$$$$$$$$$$ components/class/Blocks.vue watch getSelectedClass 1 id: %o this.blocks %o",
            //     newClass._id,
            //     this.blocks
            // );
            this.SET_SELECTED_BLOCK(null);
            await this.BLOCKS(newClass._id);
            // console.log(
            //     "components/class/Blocks.vue watch getSelectedClass 2 id: %o this.blocks %o",
            //     newClass._id,
            //     this.blocks
            // );
        }
    }
};
</script>
