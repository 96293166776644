<template>
    <v-container>
        Select course description file
        <v-file-input
            v-model="course_file"
            color="amber"
            counter
            label="File input"
            placeholder="Select course description file"
            prepend-icon="mdi-paperclip"
            outlined
            @change="sel_course_json()"
            accept="json"
            :rules="rules"
            :show-size="1000"
            id="course_meta"
            ref="course_meta"
        >
            <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="amber accent-1" label small>
                    {{ text }}
                </v-chip>

                <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                >
                    +{{ files.length - 2 }} File(s)
                </span>
            </template>
        </v-file-input>

        <v-file-input
            v-model="files"
            color="amber accent-1"
            counter
            label="File input"
            multiple
            accept=".rst"
            placeholder="Select Course Class Files"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
            @change="sel_classes_files()"
        >
            <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 3" color="amber lighten-3" label small>
                    {{ text }}
                </v-chip>

                <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                >
                    +{{ files.length - 2 }} File(s)
                </span>
            </template>
        </v-file-input>
        <v-text-field
            v-model="payment_url"
            label="Link to pay"
            placeholder="Enter payment link"
            outlined
            clearable
            @change="enable_upload_info()"
            @input="enable_upload_info()"
        ></v-text-field>
        <v-text-field
            v-model="trial_time"
            outlined
            dense
            clearable
            label="Trial duration (min.)"
            type="number"
        >
            <template v-slot:prepend>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                            mdi-help-circle-outline
                        </v-icon>
                    </template>
                    Trial duration (minutes) or "endless" trial if not
                    specified.
                </v-tooltip>
            </template>
        </v-text-field>

        <v-btn
            :loading="loading3"
            :disabled="!upload"
            color="blue-grey"
            class="ma-2 white--text"
            @click="loader()"
        >
            Upload
        </v-btn>
        <v-alert
            dense
            border="bottom"
            colored-border
            elevation="2"
            type="warning"
            color="amber"
            v-show="warning_info != ''"
        >
            {{ warning_info.statusText }}
        </v-alert>
        <v-expansion-panels v-if="ERROR && ERROR.data.length > 0">
            <v-expansion-panel v-for="(item, i) in ERROR.data" :key="i">
                <v-expansion-panel-header v-if="IsJsonString(item.message)">
                    Parsing error "{{ item.file }}" file. <br />
                    Line: {{ to_json(item.message)["err"]["line"] }} <br />
                    {{ to_json(item.message)["err"]["name"] }}<br />
                    {{ to_json(item.message)["err"]["message"] }} <br />
                    context: <br />
                    {{ to_json(item.message)["err"]["info"]["title"] }}<br />
                    {{ to_json(item.message)["err"]["info"]["text"] }}
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="IsJsonString(item.message)">
                    <vue-json-pretty
                        :data="to_json(item.message)['err']"
                        :deep="deep"
                        :show-double-quotes="showDoubleQuotes"
                        :show-length="showLength"
                        :show-line="showLine"
                        :highlight-mouseover-node="highlightMouseoverNode"
                        :collapsed-on-click-brackets="collapsedOnClickBrackets"
                        @click="handleClick"
                    >
                    </vue-json-pretty>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels v-if="!isString(warning_info)">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    System messages
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <vue-json-pretty
                        :data="to_json(warning_info)"
                        :deep="deep"
                        :show-double-quotes="showDoubleQuotes"
                        :show-length="showLength"
                        :show-line="showLine"
                        :highlight-mouseover-node="highlightMouseoverNode"
                        :collapsed-on-click-brackets="collapsedOnClickBrackets"
                        @click="handleClick"
                    >
                    </vue-json-pretty>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-row>
            <v-col cols="6" class="mx-0 px-0">
                <v-card class="mx-auto" outlined>
                    <v-card-title>
                        {{ course_file != null ? course_file.name : "" }}
                    </v-card-title>
                    <v-card-text v-show="course_file != null">
                        <vue-json-pretty
                            :data="json"
                            :deep="deep"
                            :show-double-quotes="showDoubleQuotes"
                            :show-length="showLength"
                            :show-line="showLine"
                            :highlight-mouseover-node="highlightMouseoverNode"
                            :collapsed-on-click-brackets="
                                collapsedOnClickBrackets
                            "
                            @click="handleClick"
                        >
                        </vue-json-pretty>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" class="mx-0 px-0">
                <v-card class="mx-auto" max-width="400" tile>
                    <v-list flat>
                        <v-list-item v-for="(File, i) in files" :key="i">
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="File.name"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
const file = "components/iter2/courses/AddCourseCard.vue";
const compColor = "color: #9C27B0";
// const compColorI = "color: #ff00B0";
// import JsonViewer from "vue-json-viewer";
import VueJsonPretty from "vue-json-pretty";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "AddCourseCard",
    components: {  VueJsonPretty },
    computed: {
        ...mapGetters({
            sel_course: "SELECTED_COURSE",
            ERROR: "ERROR",
            RESPONSE: "RESPONSE"
        }),
        json() {
            let cache = JSON.parse(this.loaded_json);
            return cache;
        }
    },
    data: () => ({
        max_length_description: 230,
        files: [],
        course_file: null,
        loaded_json: null,
        val: "",
        // loader: null,
        upload: false,
        loading: false,
        loading2: false,
        loading3: false,
        filterBtnTTip: false,
        payment_url: "",
        trial_time: null,
        warning_info:
            "Course description file and course class files not selected.",
        show: false,
        rules: [
            value =>
                !value ||
                value.size < 20000 ||
                "Avatar size should be less than 2 MB!"
        ],

        cache: [],
        value: "res.error",
        selectableType: "single",
        showSelectController: true,
        showLength: true,
        showLine: true,
        showDoubleQuotes: true,
        highlightMouseoverNode: true,
        highlightSelectedNode: true,
        selectOnClickNode: true,
        collapsedOnClickBrackets: true,
        path: "res",
        deep: 5,
        itemData: {},
        itemPath: ""
    }),
    watch: {},
    created() {
        console.log("created");
            console.log(`%c${file} created `, compColor)

        this.enable_upload_info();
    },
    methods: {
        ...mapActions({
            UPLOAD: "UPLOAD"
        }),
        handleFileUpload() {
            // let formData = new FormData();
            // console.log("Form Data %o", formData);
        },
        isString(val) {
            return typeof val === "string" || val instanceof String;
        },
        IsJsonString2(str) {
            // debugger;
            if (this.isString(str)) {
                return true;
            }
            return false;
            // try {
            //     JSON.parse(str);
            // } catch (e) {
            //     return false;
            // }
            // return true;
        },

        IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        to_json(item) {
            let js = item;
            if (this.IsJsonString(item)) {
                js = JSON.parse(item);
            }
            // console.log("item=%o  js=%o", item, js);
            return js;
        },
        enable_upload_info() {
            // console.log("enable_upload_info 1");
            let course_json_info = "Course description file not selected. ";
            let class_files_info = "Course class files not selected.";
            let payment_url_info = "Payment link not entered.";
            if (this.payment_url != "" || this.payment_url == null) {
                payment_url_info = "";
            }
            if (this.loaded_json != null) {
                course_json_info = "";
            }
            // console.log("enable_upload_info 2");
            if (this.files.length > 0) {
                class_files_info = "";
            }
            // console.log("enable_upload_info 3");
            this.warning_info =
                course_json_info + class_files_info + payment_url_info;
            if (this.warning_info == "") {
                this.upload = true;
            } else {
                this.upload = false;
            }
            // console.log("enable_upload_info 4");
        },
        sel_course_json() {
            // console.log(
            //     "components/course/AddCourse.vue 1 sel_course_json this %o",
            //     this
            // );
            if (this.course_file == null || this.course_file === undefined) {
                // console.log(
                //     "components/course/AddCourse.vue 2 sel_course_json this.course_file undefined"
                // );
                this.loaded_json = null;
                this.enable_upload_info();
            } else {
                // console.log(
                //     "components/course/AddCourse.vue 2 sel_course_json %o ",
                //     this.course_file
                // );
                const reader = new FileReader();
                // reader.readAsBinaryString(this.course_file);
                reader.readAsText(this.course_file);
                // console.log("reader %o", reader, this.course_file);
                reader.onload = () => {
                    this.loaded_json = reader.result;
                    this.val = JSON.stringify(reader.result);
                    console.log("reader loaded_json %o", this.loaded_json);
                    console.log("reader loaded_json %o", this.val);
                    this.enable_upload_info();
                };
            }
        },
        sel_classes_files() {
            // debugger;
            if (this.files) {
                this.files.forEach(file => {
                    const reader = new FileReader();
                    reader.readAsBinaryString(file);
                    // console.log("reader %o", reader, file);
                    reader.onload = () => {
                        /*
                         * Do something with the file
                         */
                    };
                });
            }
            this.enable_upload_info();
        },
        handleClick(path, data) {
            // console.log("click: ", path, data, treeName);
            this.itemPath = path;
            this.itemData = !data ? data + "" : data; // 处理 data = null 的情况
        },
        // handleChange(newVal, oldVal) {
        //     // console.log("newVal: ", newVal, " oldVal: ", oldVal);
        // },
        async loader() {
            // console.log(
            //     "loader json %o   course files %o",
            //     this.course_file,
            //     this.files
            // );
            // let course_meta1 = this.$refs.course_meta
            // let course_meta2 = this.$refs.course_meta.files[0];
            // let course_meta3 = this.$refs.course_meta.file;
            // console.log(
            //     "loader course_meta1 %o",
            //     course_meta1
            // );
            // console.log(
            //     "loader course_meta2 %o",
            //     course_meta2
            // );
            // console.log(
            //     "loader 1 loader %o  loading3 %o",
            //     this.loader,
            //     this.loading3
            // );
            this.loading3 = true;
            this.upload = false;

            // console.log(
            //     "loader 2 loader %o  loading3 %o",
            //     this.loader,
            //     this.loading3
            // );
            // console.log(
            //     "loader 2.1 loader %o  loading3 %o",
            //     this.loader,
            //     this.loading3
            // );
            this.warning_info = "Load course";
            await this.UPLOAD({
                payment_url: this.payment_url,
                trial_time: this.trial_time,
                course_file: this.course_file,
                classes: this.files
            });
            // console.log("this.warning_info 1 %o", this.warning_info);
            // console.log(
            //     "loader 2.2 loader %o  loading3 %o",
            //     this.loader,
            //     this.loading3
            // );
            if (this.ERROR) {
                this.warning_info = this.ERROR;
                // console.log("this.warning_info 2 %o", this.warning_info);
                if (this.IsJsonString(this.warning_info)) {
                    // debugger;
                    this.warning_info = JSON.parse(this.warning_info);
                    // console.log("this.warning_info 2.1 %o", this.warning_info);
                }
                // console.log("this.warning_info 3 %o", this.warning_info);
                // debugger;
            } else {
                this.warning_info = this.RESPONSE;
                // console.log("this.warning_info 4 %o", this.warning_info);
            }
            // this.course_file = null;
            // this.files = null;

            // console.log(
            //     "loader 3 loader %o  loading3 %o",
            //     this.loader,
            //     this.loading3
            // );
            // setTimeout(() => (this[l] = false), 3000);
            // this[l] = false
            this.loading3 = false;
            this.upload = true;
            // this.loading3 = false
            // console.log(
            //     "loader 4 loader %o  loading3 %o",
            //     this.loader,
            //     this.loading3
            // );
        }
    }
};
</script>

<style scoped>
@import "../../../assets/iter2/css/navigation_buttons.css";
</style>

