<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        :max-height="options.height"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="key_down"
    >
        <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
                {{ title }}
            </v-toolbar-title>
        </v-toolbar>
        <v-card height="options.height">
            <v-card-text class="pt-3 mb-0 pb-0 pr-6">
                <v-textarea
                    :height="options.height - 100"
                    v-model="text_area"
                    no-resize
                    outlined
                    name="input"
                >
                </v-textarea>
            </v-card-text>
            <v-card-actions dense class="mt-0 pt-0 mr-6 pb-4">
                <v-spacer class="my-0 py-0"></v-spacer>
                <v-btn
                    v-if="!options.noconfirm"
                    text
                    elevation="2"
                    @click.native="cancel"
                    >Cancel</v-btn
                >
                <v-btn elevation="2" @click.native="agree">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const file = "components/iter2/EditTextAreaDialog.vue";
const compColor = "color: #9C27B0";
export default {
    name: "EditTextAreaDialog",
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            text_area: null,
            options: {
                color: "grey lighten-3",
                width: 700,
                height: 500,
                zIndex: 200,
                noconfirm: false
            }
        };
    },
    methods: {
        open(title, message, text_area, options) {
            console.log(
                `%c${file} title %o message %o text_area %o options %o`,
                compColor,
                title,
                message,
                text_area,
                options
            );
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.text_area = text_area;
            // this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },

    }
};
</script>
