<template>
    <v-card class="mx-auto" width="300">
        <v-list two-line subheader v-if=classes>
            <v-list-item
                v-for="item in classes"
                :key="item._id"
                @click="selItem(item)"
            >
                <v-list-item-avatar>
                    <v-icon color="#ffC107">mdi-book-open</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                        v-text="item.description"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    name: "Classes",
    props: ["current_course"],
    data: () => ({
        sel: null
    }),
    created: async function() {
        // this.courses = await this.$store.dispatch("getUsers");
        // console.log("components/class/Classes.vue component 1 created");
        let id = this.SELECTED_COURSE_ID;
        // console.log("components/class/Classes.vue created 2 props: %o", id);
        if (this.$props.current_course === undefined){
        // console.log("classes created 2.1  this.$props.current_course === undefined");
            return
        }
        // let id = prp.current_course._id
        // console.log("components/class/Classes.vue created props: %o", id);
        await this.initialize(id);
        // console.log("components/class/Classes.vue created 3  course: %o", this.course);
        // console.log("components/class/Classes.vue created 3  classes: %o", this.classes);
    },
    beforeCreate() {
        // console.log("components/class/Classes.vue beforeCreate 1");
    },
    beforeMount() {
        // console.log("components/class/Classes.vue beforeMount 1");
    },
    mounted() {
        // console.log("components/class/Classes.vue mounted 1");
    },
    beforeUpdate() {
        // console.log("components/class/Classes.vue beforeUpdate 1");
    },
    updated() {
        // console.log("components/class/Classes.vue updated 1");
    },
    methods: {
        ...mapMutations(["SET_SELECTED_CLASS", "SET_SELECTED_BLOCK"]),
        ...mapActions(["CLASSES"]),
        async initialize(id) {
            // console.log("components/class/Classes.vue initialize 1 id: %o", id);
            await this.CLASSES(id);
            // console.log("components/class/Classes.vue initialize 2 id: %o", id);
        },
        selItem(id) {
            // console.log("components/class/Classes.vue selItem 1 item %o ", id);
            this.SET_SELECTED_BLOCK(null);
            this.SET_SELECTED_CLASS(id);
            // console.log("components/class/Classes.vue selItem 2 item %o ", id);
        }

    },
    computed: {
        ...mapGetters({
            course: "COURSE",
            classes: "COURSE_CLASSES",
            SELECTED_COURSE_ID: "SELECTED_COURSE_ID",
            SELECTED_BLOCK: "SELECTED_BLOCK"
        }),
        formTitle() {
            return this.editedIndex === -1 ? "New User" : "Edit User";
        }
    }
};
</script>
