<template>
    <div>
        <Info :dialog="dialog" v-on:close_info="dialog = false" />
        <v-navigation-drawer permanent app :min-width="75" :width="75">
            <v-list>
                <v-list-item style="margin-top: 15px; margin-bottom: 15px">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <a
                                v-on="on"
                                href="https://fless.pro/"
                                style="margin-left: 0; padding-left: 0"
                            >
                                <img
                                    src="../../assets/iter2/images/fless-pro.png"
                                    style="width: 45px"
                                />
                            </a>
                        </template>
                        <span>Fless.pro</span>
                    </v-tooltip>
                </v-list-item>
                <v-list-item v-for="(elem, i) in nav_elements" :key="i" link>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <router-link
                                :to="elem.to"
                                style="text-decoration: none; margin-left: 10px"
                            >
                                <v-list-item-icon
                                    v-on="on"
                                    style="margin-left: 0; padding-left: 0"
                                >
                                    <v-icon> {{ elem.icon }} </v-icon>
                                </v-list-item-icon>
                            </router-link>
                        </template>
                        <span>{{ elem.tooltip }}</span>
                    </v-tooltip>
                </v-list-item>
                <v-list-item>
                    <router-link
                        to="/login"
                        @click.native="logout"
                        style="text-decoration: none; cursor: pointer"
                    >
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-list-item-icon
                                    v-on="on"
                                    style="margin-left: 10px; padding-left: 0"
                                >
                                    <v-icon> mdi-exit-run </v-icon>
                                </v-list-item-icon>
                            </template>
                            <span>Logout</span>
                        </v-tooltip>
                    </router-link>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import Info from "./Info";
export default {
    name: "Navigations",
    components: { Info },
    data: () => ({
        dialog: false,
        nav_elements: [
            {
                icon: "mdi-account-multiple",
                to: "/v2/users",
                tooltip: "Users"
            },
            {
                icon: "mdi-bookshelf",
                to: "/v2/courses",
                tooltip: "Show all courses"
            }
        ]
    }),
    methods: {
        async logout() {
            await this.$store.dispatch("logout");
            await this.$router.push("/login");
        }
    }
};
</script>

<style scoped></style>
