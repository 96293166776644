export default {
    get_classes(class_id) {
        return {
            '1': [
                {
                    id: '1',
                    position: 0,
                    title: 'Course-1. Привет! Как дела?',
                    description: 'This is the first description',
                    solved_blocks: 25,
                    amount_blocks: 25,
                    positive_blocks: 4,
                    negative_blocks: 8,
                    head: false,
                    active: true,
                },
                {
                    id: '2',
                    position: 1,
                    title: 'Course-1. 111Привет! Как дела?222',
                    description: 'This is a veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy long description',
                    solved_blocks: 15,
                    amount_blocks: 25,
                    positive_blocks: 4,
                    negative_blocks: 16,
                    head: false,
                    active: true,
                },
                {
                    id: '3',
                    position: 2,
                    title: 'Course-1. Class 3',
                    description: 'This is',
                    solved_blocks: null,
                    amount_blocks: null,
                    positive_blocks: null,
                    negative_blocks: null,
                    head: false,
                    active: false,
                },
                {
                    id: '4',
                    position: 3,
                    title: 'Course-1. Class 4',
                    description: 'veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy long description',
                    solved_blocks: null,
                    amount_blocks: null,
                    positive_blocks: null,
                    negative_blocks: null,
                    head: false,
                    active: false,
                },
                {
                    id: '5',
                    position: 4,
                    title: 'Course-1. Class 5',
                    description: 'veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy long description',
                    solved_blocks: null,
                    amount_blocks: null,
                    positive_blocks: null,
                    negative_blocks: null,
                    head: false,
                    active: false,
                },
            ],
            '2': [
                {
                    id: '21',
                    position: 0,
                    title: 'Course-2. Привет! Как дела?',
                    description: 'This is the first description',
                    solved_blocks: 25,
                    amount_blocks: 25,
                    positive_blocks: 4,
                    negative_blocks: 8,
                    head: false,
                    active: true,
                },
                {
                    id: '2',
                    position: 1,
                    title: 'Course-2. 111Привет! Как дела?222',
                    description: 'This is a veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy long description',
                    solved_blocks: 25,
                    amount_blocks: 25,
                    positive_blocks: 4,
                    negative_blocks: 16,
                    head: false,
                    active: true,
                },
                {
                    id: '3',
                    position: 2,
                    title: 'Course-2. Class 3',
                    description: 'This is',
                    solved_blocks: 51,
                    amount_blocks: 51,
                    positive_blocks: 25,
                    negative_blocks: 25,
                    head: false,
                    active: true,
                },
                {
                    id: '4',
                    position: 3,
                    title: 'Course-2. Class 4',
                    description: 'veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy long description',
                    solved_blocks: 62,
                    amount_blocks: 62,
                    positive_blocks: 14,
                    negative_blocks: 18,
                    head: false,
                    active: true,
                },
                {
                    id: '5',
                    position: 4,
                    title: 'Course-2. Class 5',
                    description: 'veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy long description',
                    solved_blocks: 46,
                    amount_blocks: 46,
                    positive_blocks: 19,
                    negative_blocks: 22,
                    head: false,
                    active: true,
                },
            ],
        }[class_id]
    }
}
