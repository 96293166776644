<template>
    <v-container style="margin-top: 150px">
        <v-progress-circular
            :size="size_"
            :width="width"
            color="#ffc72c"
            style="padding: 50px;"
            indeterminate
        >
            <span style="color: black">
                <b>{{title_}}</b>
            </span>
        </v-progress-circular>
    </v-container>
</template>

<script>
    export default {
        name: "Loading",
        props: ['title', 'size'],
        created() {
            if (this.title !== undefined) {
                this.title_ = this.title;
            }
            if (this.size !== undefined) {
                this.size_ = this.size;
            }
        },
        data: () => ({
            width: 15,

            size_: 500,
            title_: 'Подождите, идёт загрузка',
        })
    }
</script>

<style scoped>

</style>
