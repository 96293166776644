var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"sort-by":"_created","sort-desc":_vm.sortDesc,"custom-sort":_vm.customSort},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item._created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item._created).toLocaleString('ru', { day: '2-digit', month: '2-digit', year: 'numeric' }))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Users")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","disabled":""}},on),[_vm._v("New User")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":"Email","readonly":""},model:{value:(
                                                          _vm.editedItem.email
                                                      ),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"\n                                                          editedItem.email\n                                                      "}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"First name","readonly":""},model:{value:(
                                                              _vm.editedItem.first_name
                                                          ),callback:function ($$v) {_vm.$set(_vm.editedItem, "first_name", $$v)},expression:"\n                                                              editedItem.first_name\n                                                          "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Last name","readonly":""},model:{value:(
                                                              _vm.editedItem.last_name
                                                          ),callback:function ($$v) {_vm.$set(_vm.editedItem, "last_name", $$v)},expression:"\n                                                              editedItem.last_name\n                                                          "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Active"},model:{value:(
                                                              _vm.editedItem.active
                                                          ),callback:function ($$v) {_vm.$set(_vm.editedItem, "active", $$v)},expression:"\n                                                              editedItem.active\n                                                          "}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogUserCourses),callback:function ($$v) {_vm.dialogUserCourses=$$v},expression:"dialogUserCourses"}},[_c('UserCourseSelect',{key:_vm.key_user_select,attrs:{"user":_vm.cur_user,"courses_for_list":_vm.user_courses,"courses":_vm.COURSES},on:{"close_select":function($event){_vm.dialogUserCourses = false}}})],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
                                                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("edit")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editCourses(item)}}},[_vm._v("mdi-format-list-bulleted-type")]),_c('v-icon',{attrs:{"small":"","disabled":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true},{key:"item.active",fn:function(ref){
                                                          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.active),"dark":""}})]}}])})],1)],1),_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"1"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }