<template>
  <v-container>
    <v-card
      class="d-flex justify-center custom-debug"
      :elevation="debug_elevation"
    >
      <v-card
        class="custom-block-inner custom-debug"
        :elevation="elevation"
      >
        <v-card-title
            class="justify-start display-1 custom-debug"
        >
            {{title}}
        </v-card-title>
        <v-card-text
            v-html="question"
            class="justify-start font-weight-regular title black--text custom-debug"
        />
        <v-card-text
            class="justify-start display-1 black--text custom-debug"
        >
            Ваш ответ:
        </v-card-text>
        <v-card-actions
            class="custom-debug black--text"
            :hidden="!checked"
        >
            <v-card-text
                v-html="answer"
                v-if="!accepted"
                class="justify-start font-weight-regular title custom-debug black--text custom-block-false"
            />
            <v-card-text
                v-html="answer"
                v-else
                class="justify-start font-weight-regular title custom-debug black--text custom-block-truth"
            />
        </v-card-actions>
        <v-card-actions
            class="custom-debug black--text"
            :hidden="checked"
        >
            <v-textarea
                class="justify-start custom-debug black--text"
                style="margin-right: 40px"
                background-color="amber lighten-5"
                color="orange orange-darken-1"
                :value="answer"
            />
            <v-btn color="#ffc72c" fab small dark @click="send">
                <v-icon>mdi-send</v-icon>
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-container>

</template>

<script>
  export default {
    name: "ClassBlockTextQuestion",
    props: ['title', 'question', 'elevation', 'debug_elevation', 'checked', 'answer', 'accepted'],
    methods: {
      send: function() {
        console.log(123);
      }
    }
  }
</script>

<style scoped>
    /*@import "../../../assets/css/debug.css";*/
    @import '../../../assets/css/class_block.css';
</style>
