<template>
    <v-container>
        <router-link to="/">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-first-rtbutton"
            >
                <v-icon>mdi-home</v-icon>
            </v-btn>
        </router-link>
        <router-link to="/users">
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-second-rtbutton"
            >
                <v-icon>mdi-account-multiple</v-icon>
            </v-btn>
        </router-link>
    </v-container>
</template>

<script>
export default {
    name: "NavigationAddCoursesButtons",
    props: ["navigation"],
    methods: {
        pageHeight() {
            return document.body.scrollHeight;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        toDown() {
            this.$vuetify.goTo(this.pageHeight());
        },
        toMiddle() {
            this.$vuetify.goTo((1.2 * this.pageHeight()) / 3);
        }
    }
};
</script>

<style scoped>
@import "../../assets/css/navigation_buttons.css";
@import "../../assets/css/class_title.css";
</style>
