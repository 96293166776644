
const file="main.js"
console.warn(`${file} START 1`)
// console.warn(`${file} START 1.1`)
import Vue from "vue";
console.warn(`${file} START 2 `)

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
console.warn(`${file} START 3`)

Vue.config.productionTip = false;

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount("#app");

store.watch(
    (state, getters) => {
      return [state.token, getters.isLoggedIn];
    },
    watched => {
      if (!watched[0])
            router.go("/login")
    },
    {}
  );
  