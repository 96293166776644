const file = "config/constants";
// console.warn(`${file} ` + "$$$$$$$$$$$$ init 1");

const cfg = process.env.VUE_APP_STUDENT_UI_PORT;
// console.warn(`${file} ` + "$$$$$$$$$$$$ init 2   cfg %o", cfg);
// console.warn(`${file} ` + "$$$$$$$$$$$$ init 2   process.env %o", process.env);
// const telspr_host = "testsrv.aoniti.ru";
// const telspr_port = "55001";
// const telspr = "http://" + telspr_host + ":" + telspr_port;
// const remoteServiceNameTelspr = "NITI_TELSPR";

// http://127.0.0.1:50102/api/v1/access
// const sukdtd_host = "testsrv.aoniti.ru";
// const sukdtd_host = "127.0.0.1"; // "testsrv.aoniti.ru";
// const sukdtd_host = process.env.sukdtd_host; // "testsrv.aoniti.ru";
// const sukdtd_port = process.env.sukdtd_port;
// const sukdtd = "http://" + sukdtd_host + ":" + sukdtd_port;
// let lastRequestID = 0;
const fmt_None = "";
const fmt_green = "color: green; font: 1rem/1 Tahoma;";
const fmt_green07 = "color: green; font: 0.7rem/1 Tahoma;";
const fmt_green095 = "color: green; font: 0.95rem/1 Tahoma;";
const fmt_green11 = "color: green; font: 1.1rem/1 Tahoma;";
const fmt_blue = "color: blue; font: 1rem/1 Tahoma;";
const fmt_blue07 = "color: blue; font: 0.7rem/1 Tahoma;";
const fmt_blue095 = "color: blue; font: 0.95rem/1 Tahoma;";
const fmt_blue11 = "color: blue; font: 1.1rem/1 Tahoma;";
const fmt_red = "color: red; font: 1rem/1 Tahoma;";
const fmt_red07 = "color: red; font: 0.7rem/1 Tahoma;";
const fmt_red095 = "color: red; font: 0.95rem/1 Tahoma;";
const fmt_red11 = "color: red; font: 1.1rem/1 Tahoma;";
const colorGetters = "color: #00ffB0;";
const colorGettersI = "color: #aaffB0;";
const compColor = "color: #0000f0;";
const process_env = process.env;
// console.log(
//   `\n\n\n\n${file}  ^^^^^^^^^^^^^^  process_env: ^^^^^^^^^^^^^^\n\n\n\n%o\n\n\n\n`,
//   process_env
// );

export {
  fmt_None,
  fmt_green,
  fmt_blue,
  fmt_red,
  colorGetters,
  colorGettersI,
  compColor
};