<template>
    <v-content>
      <v-container fluid>
          <router-view/>
      </v-container>
    </v-content>
</template>

<script>
    export default {
        name: 'App',
        components: {},
    };
</script>

<style>
    @import "assets/css/app.css";
</style>
