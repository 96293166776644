<template>
    <v-hover v-slot:default="{ hover }">
        <v-card
            style="min-height: 170px; padding-top: 5px; padding-bottom: 20px; display: flex"
            :style="{ cursor: cl.status !== 'forbidden' ? 'pointer' : 'auto' }"
            :elevation="
                hover && cl.status !== 'forbidden'
                    ? settings.hover_elevation
                    : settings.elevation
            "
            @click.native="() => go_to_tasks(cl)"
        >
            <v-card
                style="width: 70px; height: 70px; margin: 40px 0 0 42.5px; padding: 10px 27.5px 15px 10.3px; border-radius: 20px"
                :style="{ 'background-color': settings.back_general_color }"
                :elevation="settings.elevation"
            >
                <div
                    style="width: 50px; height: 50px; border-radius: 20px; padding: 7px 0 0 8px"
                    :style="{ 'background-color': settings.general_color }"
                >
                    <v-icon v-if="cl.status === 'forbidden'" size="35"
                        >mdi-lock</v-icon
                    >
                    <v-icon v-if="cl.status === 'progress'" size="35"
                        >mdi-clock</v-icon
                    >
                    <v-icon v-if="cl.status === 'solved'" size="35"
                        >mdi-check</v-icon
                    >
                </div>
            </v-card>
            <div
                style="
                    display: block;
                    margin-right: 25px;
                "
            >
                <div
                    class="text-justify"
                    style="font-size: 24px; margin: 35px 20px 0 40px"
                >
                    <b>{{ cl.title }}</b>
                </div>
                <div class="text-justify" style="margin: 2px 20px 0 40px">
                    {{ cl.description }}
                </div>
            </div>
        </v-card>
    </v-hover>
</template>

<script>
const file = "components/iter2/classes/Card.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff00B0";
// import utils from "../../../plugins/iter2/utils";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
export default {
    name: "Card",
    props: ["cl"],
    created() {
        console.log(
            `%c${file} created this.settings %o`,
            compColor,
            this.settings
        );
        this.settings = this.get_settings;
        console.log(
            `%c${file} created this.settings %o`,
            compColorI,
            this.settings
        );
        if (this.settings !== null) {
            this.loading = false;
            return;
        }
        // TODO: add try catch
        this.settings = this.$store.getters.settings;
    },
    data: () => ({
        settings: null,
        message: null
    }),
    computed: {
        ...mapGetters({
            get_settings: "settings"
        }),
        ...mapGetters({
            get_settings: "settings",
            course: "SELECTED_COURSE",
            COURSE_CLASSES: "COURSE_CLASSES",
            SELECTED_CLASS: "SELECTED_CLASS",
            getSelectedClass: "getSelectedClass",
            SELECTED_COURSE_ID: "SELECTED_COURSE_ID",
            SELECTED_BLOCK: "SELECTED_BLOCK"
        }),
        ...mapState(["SELECTED_COURSE"])
    },
    methods: {
        ...mapActions(["COURSES", "COURSE", "CLASSES"]),
        ...mapMutations({
            set_selected_class: "SET_SELECTED_CLASS",
            SET_SELECTED_BLOCK: "SET_SELECTED_BLOCK",
            SET_SELECTED_CLASS: "SET_SELECTED_CLASS",
            SET_CLASSES: "SET_CLASSES",
            SET_SELECTED_COURSE_ID: "SET_SELECTED_COURSE_ID"
        }),
        async go_to_tasks(class_) {
            console.log(`%c${file} go_to_tasks class_ %o`, compColor, class_);
            // TODO: add try catch
            // let args = this.change_class_args();
            // let resp = await this.$store.dispatch("change_class", args);
            // utils.message_on(this, resp);
            this.SET_SELECTED_CLASS(class_);
            await this.$router.push(`/v2/class/${class_._id}`);
        },
        change_class_args() {
            return { class_id: this.cl.id };
        }
        // async validate(resp) {
        //     if (!resp.valid) {
        //         await this.$store.dispatch("logout");
        //         await this.$router.push("/login");
        //         return false;
        //     }
        //     return true;
        // },
        // async check_auth() {
        //     if (!(await this.$store.dispatch("check_auth"))) {
        //         await this.$store.dispatch("logout");
        //         await this.$router.push("/login");
        //         return false;
        //     }
        //     return true;
        // }
    }
};
</script>

<style scoped>
@import "../../../assets/iter2/css/navigation_buttons.css";
</style>
