export default {
    get_courses() {
        return [
            {
                id: '1',
                title: 'Problem solve test111111111111111111111111111111111111111111111111111111',
                image_url: "https://media.wired.com/photos/598e35994ab8482c0d6946e0/master/w_2560%2Cc_limit/phonepicutres-TA.jpg",
                description: 'Visit ten places on our planet that are unVisit ten places on our planet that are unVisit ten places on our planet that are unVisit ten places on our planet that are undergoing the biggest changes today.Visit ten places on our planet that are undergoing the biggest changes today.Visit ten places on our planet that are undergoing the biggest changes today.',
                solved_classes: 3,
                amount_classes: 12,
                certificate: null,
                available: true,
            },
            {
                id: '2',
                title: 'XXX',
                image_url: "https://cdn.vuetifyjs.com/images/cards/mountain.jpg",
                description: 'Description-2',
                solved_classes: 9,
                amount_classes: 18,
                certificate: 'https://cdn.vuetifyjs.com/images/cards/mountain.jpg',
                available: true,
            },
            {
                id: '3',
                title: 'XXX',
                image_url: "https://cdn.vuetifyjs.com/images/cards/mountain.jpg",
                description: 'Description-3',
                solved_classes: null,
                amount_classes: null,
                certificate: null,
                available: false,
            },
            {
                id: '4',
                title: 'XXX',
                image_url: "https://cdn.vuetifyjs.com/images/cards/mountain.jpg",
                description: 'Description-4',
                solved_classes: null,
                amount_classes: null,
                certificate: null,
                available: false,
            },
            {
                id: '5',
                title: 'XXX',
                image_url: "https://cdn.vuetifyjs.com/images/cards/mountain.jpg",
                description: 'Description-5',
                solved_classes: null,
                amount_classes: null,
                certificate: null,
                available: false,
            }
        ]
    }
}
