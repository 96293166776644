<template>
    <v-container>
        <router-link
            to="/courses"
        >
            <v-btn
              fab
              fixed
              right
              top
              color="#ffc72c"
              class="custom-first-rtbutton"
            >
                <v-icon>mdi-book-open-page-variant</v-icon>
            </v-btn>
        </router-link>
        <v-btn
            fab
            fixed
            right
            top
            color="#ffc72c"
            class="custom-second-rtbutton"
            href="https://fless.pro/"
        >
            <img src="../../assets/images/fless-pro.png" style="width: 56px"/>
        </v-btn>
        <router-link
            to="/login"
        >
            <v-btn
                fab
                fixed
                right
                top
                color="#ffc72c"
                class="custom-debug custom-third-rtbutton"
            >
                <v-icon>mdi-exit-run</v-icon>
            </v-btn>
        </router-link>
  </v-container>
</template>

<script>
    export default {
        name: "NavigationAccountButtons",
        props: ['navigation'],
        methods: {
            pageHeight () {
              return document.body.scrollHeight
            },
            toTop () {
                this.$vuetify.goTo(0);
            },
            toDown () {
                this.$vuetify.goTo(this.pageHeight());
            },
            toMiddle () {
                this.$vuetify.goTo(1.2 * this.pageHeight() / 3);
            }
        }
    }
</script>

<style scoped>
    @import '../../assets/css/navigation_buttons.css';
    @import '../../assets/css/class_title.css';
</style>
