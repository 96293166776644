<template>
    <v-app class="background-color" style="padding: 15px 80px 0 80px">
        <v-snackbar v-model="show_message">
            {{ message }}
        </v-snackbar>
        <Loading class="text-center" v-if="loading" :title="title" />
        <Navigations v-if="!loading" />

        <v-container>
            <v-card
                v-if="!loading"
                :elevation="settings.elevation"
                style="min-height: 550px; overflow: hidden"
            >
                <div>
                    <v-row>
                        <v-col class="text-right pt-0 ">
                                <router-link
                                    :style="{
                                        color: settings.general_color
                                    }"
                                    style="text-decoration: none"
                                    to="/v2/users"
                                >
                                    <v-icon
                                        :color="settings.general_color"
                                        large
                                        :elevation="settings.elevation"
                                    >
                                        mdi-window-close</v-icon
                                    >
                                </router-link>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="9">
                            <div
                                class="ma-0 pa-0 mx-3 px-3 custom-app-containers offset-align-cards display-1"
                            >
                                <b>{{ title }}</b>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div
                                class=" ma-0 pa-0 mx-3 px-3 custom-app-containers offset-align-cards display-1"
                            >
                                email: {{ user.email }}
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <Card :user="user" :courses="all_courses" />
            </v-card>
        </v-container>
    </v-app>
</template>

<script>
const file = "views/iter2/UserCourses.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff00B0";
import Card from "../../components/iter2/users/Card";
import Loading from "../../components/iter2/Loading";
import Navigations from "../../components/iter2/Navigations";
import Vue from "vue";
// import utils from "../../plugins/iter2/utils";

import { mapGetters, mapActions } from "vuex";
export default {
    name: "UserCourses",
    components: { Loading, Navigations, Card },
    data: () => ({
        title: "",
        loading: true,
        message: "",
        show_message: false,
        courses: [],
        user: {},
        statuses: [],
        user_on_course: [],
        user_on_course_info: []
    }),
    created: async function() {
        console.log(
            `%c${file} created 1 this.settings %o`,
            compColor,
            this.settings
        );
        this.settings = this.get_settings;
        this.loading = true;
        this.settings = this.$store.getters.settings;
        await this.initialize(this.$route.params.id);

        this.loading = false;

        // // TODO: add try catch
        // if (utils.is_mobile()) {
        //     this.title = "Mobile version in development";
        //     this.loading = true;
        //     return;
        // }
        // this.loading = true;
        // console.log(
        //     `%c${file} created this.courses %o`,
        //     compColor,
        //     this.courses
        // );
        // await this.COURSES();
        // if (this.ERROR) {
        //     `%c${file} created this.ERROR %o   this.all_courses %o`,
        //         compColorI,
        //         this.ERROR,
        //         this.all_courses;
        //     this.loading = false;
        //     this.$router.push({ path: `/login` });
        // }

        // this.courses = this.all_courses;
        // this.loading = false;
        // console.log(
        //     `%c${file} created this.courses %o   this.all_courses %o`,
        //     compColorI,
        //     this.courses,
        //     this.all_courses
        // );
    },
    computed: {
        ...mapGetters({
            all_courses: "COURSES",
            GET_USER: "GET_USER",
            ERROR: "ERROR",
            STATUSES: "STATUSES"
        })
    },
    methods: {
        ...mapActions({
            UPDATE_USER_COURSES: "UPDATE_USER_COURSES",
            USER_STATUSES: "USER_STATUSES",
            ADD_USER_TO_COURSE: "ADD_USER_TO_COURSE",
            UPDATE_USER_STATE: "UPDATE_USER_STATE",
            USER: "USER",
            COURSES: "COURSES"
        }),

        async initialize(id) {
            console.log(`%c${file} initialize 1 id %o  `, compColorI, id);
            await this.USER(id);
            this.user = this.GET_USER;
            console.log(
                `%c${file} initialize 2 this.user %o  `,
                compColorI,
                this.user
            );
            this.title = `${this.user.first_name} ${this.user.last_name} : All courses`;
            await this.COURSES();
            this.courses = this.all_courses;
            console.log(
                `%c${file} initialize 2.1 this.all_courses %o  `,
                compColorI,
                this.all_courses
            );
            console.log(
                `%c${file} initialize 3 all_courses %o`,
                compColorI,
                this.all_courses
            );
            this.statuses = await this.USER_STATUSES(id);
            console.log(
                `%c${file}   created 1 statuses %o `,
                compColor,
                this.statuses
            );
            this.statuses = this.STATUSES;
            console.log(
                `%c${file}   created 2 statuses %o `,
                compColor,
                this.statuses
            );
            this.user_on_course.splice(0, this.user_on_course.length);
            this.user_on_course_info.splice(0, this.user_on_course_info.length);

            let i = 0;
            for (var key in this.courses) {
                let on_course = false;
                let status = this.statuses.find(
                    el => el.course_id == this.courses[key]._id
                );
                let paid = false;
                if (status != undefined && status.paid) {
                    on_course = true;
                    paid = true;
                }
                this.user_on_course.push(on_course);
                this.user_on_course_info.push({
                    index: i,
                    key: key,
                    paid: paid
                });
                Vue.set(this.user_on_course, i, this.user_on_course[i]);

                i++;
            }

            this.user_on_course.splice(this.user_on_course.length);
            console.log(
                `%c${file}  created 3 user_on_course %o this.user_on_course_info %o`,
                compColor,
                this.user_on_course,
                this.user_on_course_info
            );
        }
    }
};
</script>

<style>
@import "../../assets/iter2/css/app.css";
@import "../../assets/iter2/css/new/border.css";
@import "../../assets/iter2/css/new/courses.css";
@import "../../assets/iter2/css/new/colors.css";
</style>
