<template>
    <v-hover v-slot:default="{ hover }">
        <v-card
            style="min-height: 130px; padding-top: 5px; padding-bottom: 20px; display: flex;"
            :style="{ cursor: cl.status !== 'forbidden' ? 'pointer' : 'auto' }"
        >
            <v-card
                style="width: 80px; height: 100px; margin: 10px 0 0 42.5px; padding: 10px 27.5px 15px 10.3px; border-radius: 20px"
                :style="{ 'background-color': type_color(block_type) }"
            >
                <v-card-title
                    style=" margin: 0px 0 0 0px; padding: 0px 0px 0px 15px;"
                    class="justify-center"
                >
                    {{ cl.position }}
                </v-card-title>

                <div
                    style="width: 50px; height: 50px; border-radius: 20px;  margin: 0px 0 0 5px; padding: 7px 10px 0 8px"
                    :style="{ 'background-color': settings.general_color }"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="35" v-bind="attrs" v-on="on">{{
                                type_icon(block_type)
                            }}</v-icon>
                        </template>
                        <span>{{ block_type }}</span>
                    </v-tooltip>
                </div>
                <v-container
                    fluid
                    class="py-1 my-n1"
                    v-if="block_type == 'text' || block_type == 'video'"
                >
                    <v-radio-group v-model="block_type">
                        <v-radio
                            value="text"
                            class="py-1 my-n1"
                            @change="select_text"
                        >
                            <template v-slot:label>
                                <div>
                                    <strong>text</strong>
                                </div>
                            </template>
                        </v-radio>
                        <v-radio value="video" @change="select_video">
                            <template v-slot:label>
                                <div>
                                    <strong>video</strong>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-container>
            </v-card>

            <v-container class="pa-3 ma-0">
                <v-row no-gutters class="pa-0 ml-n2">
                    <v-col cols="1" class="pa-0 ml-0">
                        <v-btn
                            icon
                            color="primary"
                            dark
                            large
                            @click.stop="openEditTitleDialog"
                        >
                            <v-icon>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="11" class="pa-0 ml-n8 ">
                        <v-textarea
                            no-resize
                            dense
                            readonly
                            outlined
                            auto-grow
                            rows="1"
                            v-model="title"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>

                <v-row no-gutters class="pa-0 ml-n2">
                    <v-col cols="1" class="pa-0 ml-0">
                        <v-btn
                            icon
                            color="primary"
                            dark
                            large
                            @click.stop="openEditTextDialog"
                        >
                            <v-icon>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="11" class="pa-0 ml-n8 ">
                        <v-textarea
                            no-resize
                            dense
                            readonly
                            outlined
                            auto-grow
                            rows="1"
                            v-model="cl.text"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    class="pa-0 ml-n2"
                    v-if="cl.variants.length > 0"
                >
                    <v-col cols="1" class="pa-0 ml-0">
                        <v-btn
                            icon
                            color="primary"
                            dark
                            large
                            @click.stop="openEditVariantsDialog"
                        >
                            <v-icon>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="2" class="pa-0 ml-0 mt-1 pt-2">
                        Variants:
                    </v-col>
                    <v-col cols="9" class="pa-0 ml-n12 mt-n2 ">
                        <v-list>
                            <template v-for="(variant, index) in cl.variants">
                                <v-list-item
                                    class="mt-1 "
                                    :key="index"
                                    dense
                                    style="height: auto;"
                                >
                                    <v-list-item-icon class="mr-1 ">
                                        {{ index + 1 }}.
                                    </v-list-item-icon>
                                    {{ variant }}
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
                <EditTextAreaDialog ref="editTitle" />
                <EditTextAreaDialog ref="editText" />
                <EditVariantsDialog ref="editVariants" />
            </v-container>
        </v-card>
    </v-hover>
</template>

<script>
const file = "components/iter2/blocks/Card.vue";
const compColor = "color: #9C27B0";
const compColorI = "color: #ff00B0";
import EditTextAreaDialog from "../../../components/iter2/EditTextAreaDialog";
import EditVariantsDialog from "../../../components/iter2/EditVariantsDialog";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
    name: "Card",
    props: ["cl"],
    components: {
        EditTextAreaDialog,
        EditVariantsDialog
    },
    created() {
        console.log(
            `%c${file} created this.settings %o`,
            compColor,
            this.settings
        );
        this.settings = this.get_settings;
        this.title = this.$props.cl.title;
        this.text = this.$props.cl.text;
        this.block_type = this.$props.cl.block_type;
        this.variants = this.$props.cl.variants;
        console.log(
            `%c${file} created position %o this.$props.cl %o`,
            compColorI,
            this.$props.cl.position,
            this.$props.cl
        );
    },
    data: () => ({
        settings: null,
        title: null,
        text: null,
        block_type: null,
        variants: []
    }),
    computed: {
        ...mapGetters({
            get_settings: "settings"
        }),
        ...mapGetters({
            get_settings: "settings",
            course: "SELECTED_COURSE",
            COURSE_CLASSES: "COURSE_CLASSES",
            SELECTED_CLASS: "SELECTED_CLASS",
            getSelectedClass: "getSelectedClass",
            SELECTED_COURSE_ID: "SELECTED_COURSE_ID",
            SELECTED_BLOCK: "SELECTED_BLOCK"
        }),
        ...mapState(["SELECTED_COURSE"])
    },
    methods: {
        ...mapActions([
            "COURSES",
            "COURSE",
            "CLASSES",
            "UPDATE_BLOCK_ATTRIBUTES"
        ]),
        ...mapMutations({
            set_selected_class: "SET_SELECTED_CLASS",
            SET_SELECTED_BLOCK: "SET_SELECTED_BLOCK",
            SET_SELECTED_CLASS: "SET_SELECTED_CLASS",
            SET_CLASSES: "SET_CLASSES",
            SET_SELECTED_COURSE_ID: "SET_SELECTED_COURSE_ID"
        }),
        async go_to_tasks(class_) {
            console.log(`%c${file} go_to_tasks class_ %o`, compColor, class_);
        },
        type_color(block_type) {
            return this.settings.color_type_block[block_type];
        },
        type_icon(block_type) {
            return this.settings.icon_type_block[block_type];
        },
        async select_text() {
            console.log(`%c${file} select_text 1 `, compColor);
            await this.change_block_type("text");

            return;
        },
        async select_video() {
            console.log(`%c${file} select_video 1  `, compColor);
            await this.change_block_type("video");
            return;
        },
        async change_block_type(block_type) {
            console.log(
                `%c${file} change_type_block 1  block_type %o`,
                compColor,
                block_type
            );
            console.log(
                `%c${file} change_block_type 2  cl %o`,
                compColor,
                this.$props.cl
            );
            await this.UPDATE_BLOCK_ATTRIBUTES({
                _id: this.$props.cl._id,
                _etag: this.$props.cl._etag,
                title: this.$props.cl.title,
                text: this.$props.cl.text,
                block_type: block_type
            });
            // this.$props.cl.title=line
            // this.change_block_type = line;
        },
        async openEditTitleDialog() {
            if (
                await this.$refs.editTitle.open(
                    "Edit block title.",
                    "",
                    this.title
                )
            ) {
                this.editTitle(this.$refs.editTitle.text_area);
            }
        },
        async editTitle(line) {
            await this.UPDATE_BLOCK_ATTRIBUTES({
                _id: this.$props.cl._id,
                _etag: this.$props.cl._etag,
                title: line,
                text: this.$props.cl.text,
                block_type: this.$props.cl.block_type
            });
            // this.$props.cl.title=line
            this.title = line;
        },
        async openEditTextDialog() {
            if (
                await this.$refs.editText.open(
                    "Edit block text.",
                    "",
                    this.text
                )
            ) {
                console.log(
                    `%c${file} editDescription 1 editDescription %o`,
                    compColor,
                    this.$refs.editText
                );
                this.editText(this.$refs.editText.text_area);
            }
        },
        async openEditVariantsDialog() {
            if (
                await this.$refs.editVariants.open(
                    "Edit variants.",
                    "",
                    this.variants
                )
            ) {
                console.log(
                    `%c${file} editDescription 1 editDescription %o`,
                    compColor,
                    this.$refs.editText
                );
                this.editVariants(this.$refs.editVariants.variants);
            }
        },
        async editText(text) {
            await this.UPDATE_BLOCK_ATTRIBUTES({
                _id: this.$props.cl._id,
                _etag: this.$props.cl._etag,
                title: this.$props.cl.title,
                text: text,
                block_type: this.$props.cl.block_type
            });
            this.text = text;
        },
        async editVariants(variants) {
            await this.UPDATE_BLOCK_ATTRIBUTES({
                _id: this.$props.cl._id,
                _etag: this.$props.cl._etag,
                title: this.$props.cl.title,
                text: this.$props.cl.text,
                block_type: this.$props.cl.block_type,
                variants: JSON.parse(JSON.stringify(variants))
            });
            this.variants = JSON.parse(JSON.stringify(variants));
        }
    }
};
</script>

<style scoped>
@import "../../../assets/iter2/css/navigation_buttons.css";
</style>
